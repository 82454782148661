import { styled } from '@mui/material';
import { Grid, Box } from '@sezzle/sezzle-mui';

export const StyledHeroTextContainer = styled(Grid)((props) => ({
  marginBottom: props.theme.spacing(2),
}));

export const StyledHeroTextButtonContainer = styled(Grid)((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: props.theme.spacing(1),
  margin: props.theme.spacing(2, 'auto'),
}));

export const StyledHeroTextDescriptionContainer = styled(Box)((props) => ({
  marginTop: props.theme.spacing(2),
  marginBottom: props.theme.spacing(2),
}));

export const StyledHeroTextButton = styled(Grid)((props) => ({
  minWidth: 170,
  margin: props.theme.spacing(1, 0),
}));
