import { lazy } from 'react';

/*
 * Credit to raphael-leger:
 *  https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb#file-lazywithretry-js
 *
 */

const AlreadyForceRefreshed = 'already-force-refreshed';

export const lazyWithRetry: typeof lazy = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.sessionStorage.getItem(AlreadyForceRefreshed) || 'false'
    ) as boolean;

    try {
      const component = await componentImport();

      window.sessionStorage.setItem(AlreadyForceRefreshed, 'false');

      return component;
    } catch (error) {
      if (pageHasAlreadyBeenForceRefreshed) {
        // The page has already been reloaded
        // Assuming that user is already using the latest version of the application.
        // Let's let the application crash and raise the error.
        throw error;
      }

      // Assuming that the user is not on the latest version of the application.
      // Let's refresh the page immediately.
      window.sessionStorage.setItem(AlreadyForceRefreshed, 'true');
      window.location.reload();
    }
  });

export default lazyWithRetry;
