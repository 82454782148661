import React, { useState } from 'react';
import {
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
} from '@sezzle/sezzle-mui';
import { renderCTAOptions } from '../../../../../renderCTAOptions';
import { CategoryAccordionProps } from '../../types';
import { ExpandMore } from '@mui/icons-material';
import {
  StyledCategoryAccordionWrapper,
  StyledCategoryAccordion,
  StyledCategoryAccordionHeaderText,
} from './styles';

export const CategoryAccordion = ({
  listHeader,
  list,
  headerLevel,
}: CategoryAccordionProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <StyledCategoryAccordionWrapper>
      <StyledCategoryAccordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        disableGutters
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-label={listHeader}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledCategoryAccordionHeaderText
            variant="Body"
            fontWeight="medium"
          >
            {listHeader}
          </StyledCategoryAccordionHeaderText>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {list?.length !== 0 &&
              list?.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={renderCTAOptions(
                      item?.sys?.contentType?.sys?.id ||
                        item?.contentfulContentType,
                      item
                    )}
                  />
                </ListItem>
              ))}
          </List>
        </AccordionDetails>
      </StyledCategoryAccordion>
    </StyledCategoryAccordionWrapper>
  );
};
