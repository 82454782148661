import React from 'react';
import CTA from './CTA';
import ImageLink from './ImageLink';
import Paragraph from './Paragraph';
import Text from './Text';
import { ContentfulContentType } from './types/enums';

export const renderFeaturedMobileSpot = (fields, sectionType) => {
  switch (sectionType) {
    case ContentfulContentType.IMAGELINK:
      return (
        <ImageLink
          node_locale={fields.node_locale}
          shouldTextShow={fields.shouldTextShow}
          url={fields.imageUrl || fields.url}
          image={fields.image}
          textAlignment={fields.textAlignment}
          assistiveText={fields.assistiveText}
        />
      );
    case ContentfulContentType.PARAGRAPH:
      return (
        <Paragraph
          paragraph={fields.paragraph}
          bodyFontSize={fields.bodyFontSize}
        />
      );
    case ContentfulContentType.CTA:
      return <CTA fields={fields} />;
    case ContentfulContentType.TEXT:
      return <Text {...fields} />;
    default:
      console.log(`Section type not found: ${sectionType}`);
      return null;
  }
};

export default renderFeaturedMobileSpot;
