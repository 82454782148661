import React from 'react';
import Image from './Image';
import ImageLink from './ImageLink';
import { ContentfulContentType } from './types/enums';

export const renderImageComponent = (sectionType, fields) => {
  switch (sectionType) {
    case ContentfulContentType.IMAGE:
      return <Image fields={fields} />;
    case ContentfulContentType.IMAGELINK:
      return (
        <ImageLink
          node_locale={fields.node_locale}
          shouldTextShow={fields.shouldTextShow}
          url={fields.imageUrl || fields.url}
          image={fields.image}
          textAlignment={fields.textAlignment}
          assistiveText={fields.assistiveText}
        />
      );
    default:
      console.log(`Section type not found: ${sectionType}`);
      return null;
  }
};

export default renderImageComponent;
