import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Image from '../Image';
import { ImageLink as ImageLinkProps } from './types/types';
import { defaultLocale } from '../types/constants';

export const StyledImageLink = styled.a`
  p {
    font-size: 1rem;
    margin-top: 1rem;
  }

  &:hover {
    p {
      text-decoration: underline;
      color: ${(props: any) => props.theme.secondary};
    }
  }

  .center {
    text-align: center;
    display: block;
  }

  .left {
    text-align: center;
    display: block;
  }

  .right {
    text-align: center;
    display: block;
  }
`;

export const StyledLinkImage = styled(Link)`
  p {
    font-size: 1rem;
    margin-top: 1rem;
  }

  &:hover {
    p {
      text-decoration: underline;
      color: ${(props: any) => props.theme.secondary};
    }
  }

  .center {
    text-align: center;
    display: block;
  }

  .left {
    text-align: center;
    display: block;
  }

  .right {
    text-align: center;
    display: block;
  }
`;

const ImageLink = ({
  node_locale,
  shouldTextShow,
  url,
  image,
  textAlignment,
  assistiveText,
}: ImageLinkProps) => {
  const isBrowser = typeof window !== 'undefined';
  const localePrefix =
    node_locale && node_locale === defaultLocale
      ? ''
      : `/${node_locale?.toLowerCase()}`;
  const [buttonURL, setButtonURL] = React.useState('');
  const [URL, setURL] = React.useState(url?.url || url);

  React.useEffect(
    () => setButtonURL(!URL?.startsWith('/') ? URL : `${localePrefix}${URL}`),
    [url, localePrefix]
  );

  React.useEffect(() => {
    let observer: MutationObserver;
    const divElement = document.getElementById('one_link_url');
    if (divElement) {
      // set the url
      const val = (divElement as HTMLInputElement).value;
      if (isBrowser && URL === '{{af_smart_script_link}}') {
        setButtonURL(val);
      }
    } else {
      observer = new MutationObserver(() => {
        const divElement = document.getElementById('one_link_url');
        if (divElement) {
          observer.disconnect();
          observer = null;
          // set the url
          const val = (divElement as HTMLInputElement).value;
          if (isBrowser && URL === '{{af_smart_script_link}}') {
            setButtonURL(val);
          }
        }
      });
      observer.observe(document, { subtree: true, childList: true });
    }

    return () => {
      if (observer) {
        observer.disconnect();
        observer = null;
      }
    };
  }, []);

  return URL?.startsWith('/') ? (
    <StyledLinkImage
      to={`${localePrefix}${URL}`}
      data-component-type="image-link-text"
      className="trk-ga"
    >
      <Image fields={image.image || image} />
      {shouldTextShow ? (
        <p className={textAlignment || 'center'}>{assistiveText}</p>
      ) : (
        <span className="sr">{assistiveText}</span>
      )}
    </StyledLinkImage>
  ) : (
    <StyledImageLink
      href={buttonURL}
      target={URL?.startsWith('https://sezzle') ? '_blank' : undefined}
      rel={URL?.startsWith('https://sezzle') ? 'noreferrer' : undefined}
      data-component-type="image-link-text"
      className="trk-ga"
    >
      <Image fields={image} />
      {shouldTextShow ? (
        <p className={textAlignment || 'center'}>{assistiveText}</p>
      ) : (
        <span className="sr">{assistiveText}</span>
      )}
    </StyledImageLink>
  );
};

export default ImageLink;
