import React, { Suspense } from 'react';
import CategorizedDisplay from '../CategorizedDisplay';
import LanguageSelectorContainer from '../LanguageSelectorContainer';
import CTA from '../CTA';
import Headline from '../Headline';
import ImageLink from '../ImageLink';
import SetOf from '../SetOf';
import Wrapped from '../Wrapped';
import SideBySideAndStacked from '../SideBySideAndStacked';
import { ContentfulContentType } from '../types/enums';
import { PageComponents as PageComponentsProps } from './types/types';
import lazyWithRetry from '../../utils/lazyWithRetry';
import ClientOnly from '../ClientOnly';

const AutoCarousel = React.lazy(() => import('../AutoCarousel'));
const Carousel = React.lazy(() => import('../Carousel'));
const EmbeddedScript = lazyWithRetry(() => import('../EmbeddedScript'));

const PageComponents = ({
  sectionType,
  section,
  pageContext,
}: PageComponentsProps) => {
  const renderComponent = () => {
    switch (sectionType) {
      case ContentfulContentType.CAROUSEL:
        switch (section?.shouldAutoSlide) {
          case true:
            return (
              <AutoCarousel
                slides={section.slides}
                mobileVisibleSlides={section.mobileVisibleSlides}
                desktopVisibleSlides={section.desktopVisibleSlides}
                delayBetweenTransitions={section.delayBetweenTransitions}
              />
            );
          case false:
            return (
              <Carousel
                slides={section.slides}
                mobileVisibleSlides={section.mobileVisibleSlides}
                desktopVisibleSlides={section.desktopVisibleSlides}
              />
            );
          default:
            return (
              <Carousel
                slides={section.slides}
                mobileVisibleSlides={section.mobileVisibleSlides}
                desktopVisibleSlides={section.desktopVisibleSlides}
              />
            );
        }
      case ContentfulContentType.CATEGORIZEDDISPLAY:
        return (
          <CategorizedDisplay
            categorizedElements={section.categorizedElements}
            categorizedLinks={section.categorizedLinks}
            displayAs={section.displayAs}
            id={section.id}
            filterByText={section.filterByText}
          />
        );
      case ContentfulContentType.EMBEDDEDSCRIPT:
        return (
          <ClientOnly>
            <Suspense fallback={<div />}>
              <EmbeddedScript
                scriptSource={section.scriptSource}
                scriptInnerHtml={section.scriptInnerHtml}
                scriptAppendToId={section.scriptAppendToId}
                embeddedHtml={section.embeddedHtml}
              />
            </Suspense>
          </ClientOnly>
        );
      case ContentfulContentType.CTA:
        return <CTA fields={section} />;
      case ContentfulContentType.HEADLINE:
        return (
          <Headline
            contentfulid={section.contentfulid}
            header={section.header}
            alignment={section.alignment}
            subcopy={section.subcopy}
            subCopySmall={section.subCopySmall}
            link={section.link}
          />
        );
      case ContentfulContentType.IMAGELINK:
        return (
          <ImageLink
            node_locale={section.node_locale}
            shouldTextShow={section.shouldTextShow}
            url={section.imageUrl || section.url}
            image={section.image}
            textAlignment={section.textAlignment}
            assistiveText={section.assistiveText}
          />
        );
      case ContentfulContentType.SETOF:
        return <SetOf fields={section} />;
      case ContentfulContentType.SIDEBYSIDEANDSTACKED:
        return (
          <SideBySideAndStacked
            sectionId={section.sectionId}
            imageSide={section.imageSide}
            image={section.image}
            content={section.content}
            backgroundColor={section.backgroundColor}
          />
        );
      case ContentfulContentType.SPECIFIC:
        switch (section.specificContentType) {
          // case ('Store Search'):
          //   return <StoreSearch />
          case 'Language Selector':
            return (
              <LanguageSelectorContainer
                languageSelectorTranslation={
                  section.languageSelectorTranslation
                }
                pageContext={pageContext}
              />
            );
          default:
            return null;
        }
      case ContentfulContentType.WRAPPED:
        return <Wrapped fields={section} />;
      default:
        console.log(`Section type not found: ${sectionType}`);
        return null;
    }
  };
  return <>{renderComponent()}</>;
};

export default PageComponents;
