import { styled } from '@mui/material';
import { Box, IconButton } from '@sezzle/sezzle-mui';

export const StyledSearchMenuDrawer = styled(Box)(() => ({
  position: 'relative',
}));

export const StyledSearchMenuCloseIconButton = styled(IconButton)(() => ({
  float: 'right',
}));

export const StyledSearchSuggestions = styled(Box)((props) => ({
  margin: props.theme.spacing(4, 0, 1, 1),
}));
