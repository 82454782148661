/* eslint-disable max-len */
import ISO6391 from 'iso-639-1';
import countries from 'i18n-iso-countries';

export const setPrefix = (functionality: string, url: string) => {
  switch (functionality) {
    case 'Phone':
      return `tel:${url}`;
    case 'Email':
      return `mailto:${url}`;
    case 'Download':
      return url;
    case 'Navigation':
      return url;
    default:
      return url;
  }
};

const getLanguageCodeFromLocale = (
  locale: string,
  SUPPORTED_LOCALES: Array<string>,
  DEFAULT_LOCALE: string
): string => {
  if (!locale) return '';
  if (SUPPORTED_LOCALES.includes(locale)) {
    return locale.split('-')[0];
  }
  return DEFAULT_LOCALE;
};

export const getCountryCodeFromLocale = (
  locale: string,
  DEFAULT_LOCALE: string
): string => {
  if (!locale) {
    return DEFAULT_LOCALE;
  }

  const splitLocale = locale.split('-');
  if (splitLocale.length < 2) {
    // Locale should be in the form 'en-US' 'fr-CA'
    return DEFAULT_LOCALE;
  }

  return splitLocale[1];
};

export const getLanguageFromLocale = (
  locale: string,
  SUPPORTED_LOCALES: Array<string>,
  DEFAULT_LOCALE: string
): string => {
  if (!locale) return '';
  return ISO6391.getNativeName(
    getLanguageCodeFromLocale(locale, SUPPORTED_LOCALES, DEFAULT_LOCALE)
  );
};

export const getCountryNameFromCountryCode = (
  locale: string,
  SUPPORTED_LOCALES: Array<string>,
  DEFAULT_LOCALE: string
): string => {
  if (!locale) return '';
  const languageName = `${getLanguageCodeFromLocale(locale, SUPPORTED_LOCALES, DEFAULT_LOCALE)}`;
  const countryName = `${getCountryCodeFromLocale(locale, DEFAULT_LOCALE)}`;

  return countries.getName(
    countryName.toUpperCase(),
    languageName.toLowerCase(),
    { select: 'official' }
  );
};

export const getComponentNameFromContentfullName = (
  contentfullName?: string
) => {
  return contentfullName !== undefined
    ? contentfullName.split('//').pop().trim().split(' ').join('_')
    : undefined;
};
