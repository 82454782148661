import React, { useState } from 'react';
import { AccordionLineItem as AccordionLineItemProps } from './types/types';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { getComponentNameFromContentfullName } from '../../utils/helpers';
import TagWrapper from '../TagWrapper';

const HeaderButton = styled.button<{ $fontSize?: string }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: none;
  background: none;
  color: inherit;
  font-size: ${(props) => props.$fontSize || 'inherit'};
  cursor: pointer;
`;

const HeaderText = styled.span<{ $textAlign: string }>`
  flex-grow: 1;
  text-align: ${(props) => props.$textAlign};
`;

const AccordionLineItem = ({
  id,
  name,
  headerText,
  headerTag,
  headerFontSize,
  headerTextAlign,
  panelText: unparsedPanelText,
  panelTag,
  panelFontSize,
  panelTextAlign,
  chevronSize,
}: AccordionLineItemProps) => {
  const [open, setOpen] = useState(false);

  const panelText = React.useMemo(
    () => parse(unparsedPanelText.panelText),
    [unparsedPanelText]
  );
  const headerId = React.useMemo(
    () => getComponentNameFromContentfullName(name) + '_' + id.substring(0, 3),
    [name, id]
  );
  const panelId = React.useMemo(() => headerId + '_panel', [headerId]);

  return (
    <>
      <TagWrapper tag={headerTag}>
        <HeaderButton
          onClick={() => setOpen(!open)}
          aria-expanded={open}
          id={headerId}
          aria-controls={panelId}
          $fontSize={headerFontSize}
        >
          <HeaderText $textAlign={headerTextAlign}>{headerText}</HeaderText>
          {open ? (
            <ExpandLess fontSize={chevronSize} />
          ) : (
            <ExpandMore fontSize={chevronSize} />
          )}
        </HeaderButton>
      </TagWrapper>
      {open && (
        <TagWrapper
          tag={panelTag}
          aria-labelledby={headerId}
          id={panelId}
          fontSize={panelFontSize}
          textAlign={panelTextAlign}
        >
          <>{panelText}</>
        </TagWrapper>
      )}
    </>
  );
};

export default AccordionLineItem;
