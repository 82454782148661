import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Image from '../Image';
import { ImageLinkWithText as ImageLinkWithTextProps } from './types/types';

export const StyledImageLink = styled.a`
  p {
    font-size: 1rem;
    margin-top: 1rem;
  }

  &:hover {
    p {
      text-decoration: underline;
      color: ${(props: any) => props.theme.secondary};
    }
  }

  .center {
    text-align: center;
    display: block;
  }

  .left {
    text-align: center;
    display: block;
  }

  .right {
    text-align: center;
    display: block;
  }
`;

export const StyledLinkImage = styled(Link)`
  p {
    font-size: 1rem;
    margin-top: 1rem;
  }

  &:hover {
    p {
      text-decoration: underline;
      color: ${(props: any) => props.theme.secondary};
    }
  }

  .center {
    text-align: center;
    display: block;
  }

  .left {
    text-align: center;
    display: block;
  }

  .right {
    text-align: center;
    display: block;
  }
`;

const ImageLinkWithText = ({
  url,
  image,
  textAlignment,
  displayText,
}: ImageLinkWithTextProps) => {
  return url?.url?.startsWith('/') ? (
    <StyledLinkImage
      to={url?.url || url}
      data-component-type="image-link-text"
      className="trk-ga"
    >
      <Image fields={image} />
      <p className={textAlignment}>{displayText}</p>
    </StyledLinkImage>
  ) : (
    <StyledImageLink
      href={url?.url || url}
      {...(url?.url?.startsWith('https://sezzle')
        ? null
        : { target: '_blank' })}
      {...(url?.url?.startsWith('https://sezzle')
        ? null
        : { rel: 'noreferrer' })}
      data-component-type="image-link-text"
      className="trk-ga"
    >
      <Image fields={image} />
      <p className={textAlignment}>{displayText}</p>
    </StyledImageLink>
  );
};

export default ImageLinkWithText;
