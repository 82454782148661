import React from 'react';
import LinkButton from './LinkButton';
import SezzleButton from './SezzleButton';
import { CTASelections } from '../types/enums';
import { SezzleButtonProps } from './SezzleButton/types';
import { LinkButtonProps } from './LinkButton/types';
import useHasMounted from '../../utils/useHasMounted';

interface OwnProps {
  fields: any;
  fullWidth?: boolean;
  sezzleButtonSize?: Pick<SezzleButtonProps, 'buttonSize'>;
  linkButtonSize?: Pick<LinkButtonProps, 'buttonSize'>;
}

export const renderSelectedCTA = (
  fields,
  fullWidth,
  sezzleButtonSize,
  linkButtonSize
) => {
  switch (fields.style) {
    case CTASelections.PrimaryButton:
      return (
        <SezzleButton
          type={fields.style}
          node_locale={fields.node_locale}
          url={fields.url}
          style={fields.style}
          displayText={fields.displayText}
          function={fields.function}
          fullWidth={fullWidth}
          buttonSize={sezzleButtonSize}
        />
      );
    case CTASelections.SecondaryButton:
      return (
        <SezzleButton
          type={fields.style}
          node_locale={fields.node_locale}
          url={fields.url}
          style={fields.style}
          displayText={fields.displayText}
          function={fields.function}
          fullWidth={fullWidth}
          buttonSize={sezzleButtonSize}
        />
      );
    case CTASelections.Link:
      return (
        <LinkButton
          node_locale={fields.node_locale}
          url={fields.url}
          displayText={fields.displayText}
          function={fields.function}
          buttonSize={linkButtonSize}
        />
      );
    case CTASelections.MainNavBarNavigationLink:
      return (
        <LinkButton
          node_locale={fields.node_locale}
          url={fields.url}
          displayText={fields.displayText}
          function={fields.function}
          buttonSize={linkButtonSize}
        />
      );
    default:
      return (
        <LinkButton
          node_locale={fields.node_locale}
          url={fields.url}
          displayText={fields.displayText}
          function={fields.function}
          buttonSize={linkButtonSize}
        />
      );
  }
};

const CTA = (props: Partial<OwnProps>) => {
  const { fields, fullWidth, sezzleButtonSize, linkButtonSize } = props;

  if (!fields.isMobileOnly) {
    return renderSelectedCTA(
      fields,
      fullWidth,
      sezzleButtonSize,
      linkButtonSize
    );
  }

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }
  const deviceIsMobile = window.innerWidth <= 768;
  if (!deviceIsMobile) {
    return null;
  }

  return renderSelectedCTA(fields, fullWidth, sezzleButtonSize, linkButtonSize);
};

export default CTA;
