/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import renderCollapsibleOptions from '../../../../../renderCollapsibleOptions';
import { FooterLinks as FooterLinksProps } from '../../../../../Footer/types/types';

const ExpandedLists = styled.div`
  li {
    line-height: 1.5;
  }

  li.szl__accordion__heading {
    a {
      font-family: ${(props: any) => `${props.theme.bodyFont}`} !important;
      font-weight: 800;
      font-size: 0.875rem;
      margin: 0;
    }
  }

  li.szl__subcat {
    a {
      font-size: 0.8rem;
    }
  }

  ul {
    margin-bottom: 1rem;
  }
`;

const CategoryLinks = ({ listHeader, list }: FooterLinksProps) => {
  return (
    <ExpandedLists>
      <h2 className="sr">{listHeader}</h2>
      <ul>
        {list?.length !== 0 &&
          list?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li
              key={index}
              className={
                index === 0 ? 'szl__accordion__heading' : 'szl__subcat'
              }
            >
              {renderCollapsibleOptions(
                item?.sys?.contentType?.sys?.id || item?.contentfulContentType,
                item
              )}
            </li>
          ))}
      </ul>
    </ExpandedLists>
  );
};

export default CategoryLinks;
