import React, { Suspense, useContext } from 'react';
import DesktopAppBarMenu from './components/DesktopAppBarMenu';
import { ScreenSizeConfiguratorContext } from '../../../../library/shared-ui/components/ScreenSizeConfigurator';
import { Typography } from '@sezzle/sezzle-mui';
import { NavigationBarAndMenuProps } from './types';
import {
  StyledAppBar,
  StyledToolBar,
  StyledAppBarLogoWrapper,
  StyledAppBarLogoContainer,
  StyledAppBarWebButtonsContentContainer,
  StyledAppBarContainer,
} from './styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SearchMenu } from './components/SearchMenu';
import HamburgerMenu from './components/HamburgerMenu';

const NavigationBarAndMenu = ({
  featuredLinksMobileMenu,
  mobileNavigationLinks,
  featuredMobileSpot,
  ctaButtons,
  logo,
  navigationLinks,
  showSearchSuggestions,
  mobileShopMenu,
  searchSuggestions,
  mobileButton,
}: NavigationBarAndMenuProps) => {
  const { screenConfig } = useContext(ScreenSizeConfiguratorContext);

  const renderMenuIcon = () => {
    return (
      <HamburgerMenu
        featuredLinksMobileMenu={featuredLinksMobileMenu}
        mobileNavigationLinks={mobileNavigationLinks}
        featuredMobileSpot={featuredMobileSpot}
        ctaButtons={ctaButtons}
        screenConfig={screenConfig}
      />
    );
  };

  const renderLogo = () => {
    const logoUrl = logo?.imageUrl?.url || logo?.url;
    const assistiveText = logo?.assistiveText;

    const img = getImage(logo?.image?.image?.gatsbyImageData);

    if (logoUrl) {
      return (
        <StyledAppBarLogoWrapper>
          <StyledAppBarLogoContainer>
            <a href={logo?.imageUrl?.url || logo?.url}>
              <GatsbyImage
                image={img}
                loading="eager"
                alt="Go to home page. Sezzle logo."
                objectFit="contain"
              />
            </a>
          </StyledAppBarLogoContainer>
        </StyledAppBarLogoWrapper>
      );
    }
    return <Typography>{assistiveText}</Typography>;
  };

  const renderSearchIcon = () => {
    if (showSearchSuggestions) {
      return (
        <SearchMenu
          fields={mobileShopMenu}
          searchSuggestions={searchSuggestions}
          mobileButton={mobileButton}
          screenConfig={screenConfig}
        />
      );
    }
    return null;
  };

  const renderNavigationBar = () => {
    return (
      <StyledAppBarWebButtonsContentContainer>
        {showSearchSuggestions && (
          <Suspense fallback={<div />}>{searchSuggestions}</Suspense>
        )}
        <DesktopAppBarMenu
          navigationLinks={navigationLinks}
          ctaButtons={ctaButtons}
        />
      </StyledAppBarWebButtonsContentContainer>
    );
  };

  return (
    <StyledAppBar
      position="sticky"
      color="inherit"
    >
      <StyledToolBar>
        <StyledAppBarContainer>
          {renderMenuIcon()}
          {renderLogo()}
          {renderNavigationBar()}
          {renderSearchIcon()}
        </StyledAppBarContainer>
      </StyledToolBar>
    </StyledAppBar>
  );
};

export default React.memo(NavigationBarAndMenu);
