import React from 'react';
import HeaderAndDescription from './HeaderAndDescription';
import Image from './Image';
import { ContentfulContentType } from './types/enums';

export const renderSideBySideOptions = (sectionType, fields) => {
  switch (sectionType) {
    case ContentfulContentType.HEADERANDDESCRIPTION:
      return (
        <HeaderAndDescription
          superHeading={fields.superHeading}
          description={fields.description}
          descriptionSmall={fields.descriptionSmall}
          button={fields.button}
        />
      );
    case ContentfulContentType.IMAGE:
      return <Image fields={fields} />;
    default:
      console.log(`Section type not found: ${sectionType}`);
      return null;
  }
};

export default renderSideBySideOptions;
