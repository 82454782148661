import { styled } from '@mui/material';
import { Box, List, IconButton } from '@sezzle/sezzle-mui';

export const StyledHamburgerMenuDrawer = styled(Box)(() => ({
  position: 'relative',
}));

export const StyledHamburgerMenuContent = styled(Box)((props) => ({
  marginTop: props.theme.spacing(2),
}));

export const StyledButtonList = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));

export const StyledCloseIcon = styled(IconButton)(() => ({
  float: 'right',
}));
