import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { Paragraph as ParagraphProps } from './types/types';

const StyledParagraph = styled.p`
  &.body__copy {
    &--large {
      font-size: 1.125rem;
      line-height: 32px;
      font-weight: normal;
      font-style: normal;
    }

    &--small {
      font-size: 1rem;
      line-height: 24px;
      font-weight: normal;
      font-style: normal;
    }
  }
`;

const Paragraph = ({ paragraph, bodyFontSize }: ParagraphProps) => {
  const t = React.useMemo(
    () => parse(paragraph?.paragraph || paragraph),
    [paragraph]
  );
  return (
    <StyledParagraph className={`body__copy--${bodyFontSize || 'small'}`}>
      {t}
    </StyledParagraph>
  );
};

export default Paragraph;
