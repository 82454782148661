import React, { useState, useEffect, createContext } from 'react';
import {
  ScreenConfig,
  ScreenSizeConfiguratorContextValue,
  ScreenSizeConfiguratorProps,
} from './types';

export const ScreenSizeConfiguratorContext =
  createContext<ScreenSizeConfiguratorContextValue>({
    screenConfig: {
      isMobile: false,
      isTablet: false,
      isDesktop: false,
    },
  });

export const ScreenSizeConfigurator: React.FC<ScreenSizeConfiguratorProps> = ({
  children,
}) => {
  const [screenConfig, setScreenConfig] = useState<ScreenConfig>({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  const [minWidth, setMinWidth] = useState<number | undefined>(undefined);
  const [maxWidth, setMaxWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      const isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;
      const isDesktop = window.innerWidth > 1024;

      setScreenConfig({
        isMobile,
        isTablet,
        isDesktop,
      });
    };

    // Initial setup on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenConfig.isMobile) {
      setMinWidth(0);
      setMaxWidth(768);
    } else if (screenConfig.isTablet) {
      setMinWidth(768);
      setMaxWidth(1024);
    } else {
      setMinWidth(1024);
      setMaxWidth(undefined);
    }
  }, [screenConfig]);

  const contextValue: ScreenSizeConfiguratorContextValue = {
    screenConfig: {
      isMobile: screenConfig.isMobile,
      isTablet: screenConfig.isTablet,
      isDesktop: screenConfig.isDesktop,
    },
    minWidth,
    maxWidth,
  };

  return (
    <ScreenSizeConfiguratorContext.Provider value={contextValue}>
      {children}
    </ScreenSizeConfiguratorContext.Provider>
  );
};
