import React, { Suspense } from 'react';
import { Grid } from '@sezzle/sezzle-mui';
import EmbeddedScript from '../../../../EmbeddedScript';
import renderImageComponent from '../../../../renderImageComponent';
import { HeroTemplateProps } from './types';
import HeroText from '../../HeroText';
import { StyledHeroTemplateWrapper } from '../styles';
import ClientOnly from '../../../../ClientOnly';

const HeroTemplateForm = ({
  h1Header,
  h1HeaderFont,
  mobileCtas,
  buttonPlacementBelowSubcopy,
  heroImage,
  bodyCopy1,
  bodyCopy2,
  bodyCopy3,
  embeddedScript,
  bodyCopy1Font,
  bodyCopy2Font,
  bodyCopy3Font,
  extraSmallLeftSide,
  smallLeftSide,
  mediumLeftSide,
  largeLeftSide,
}: HeroTemplateProps) => {
  h1HeaderFont = h1HeaderFont || 'CTALarge';
  bodyCopy1Font = bodyCopy1Font || 'Title3';
  bodyCopy2Font = bodyCopy2Font || 'Title3';
  bodyCopy3Font = bodyCopy3Font || 'Title3';

  const leftXS = parseInt(extraSmallLeftSide) || 12;
  const leftS = parseInt(smallLeftSide) || 6;
  const leftM = parseInt(mediumLeftSide) || 6;
  const leftL = parseInt(largeLeftSide) || 6;

  const rightXS = 12 - leftXS;
  const rightS = 12 - leftS;
  const rightM = 12 - leftM;
  const rightL = 12 - leftL;

  return (
    <StyledHeroTemplateWrapper
      container
      spacing={3}
      data-component-type="hero-template-1"
    >
      <Grid
        item
        xs={leftXS}
        sm={leftS}
        md={leftM}
        lg={leftL}
      >
        <HeroText
          h1Header={h1Header}
          h1HeaderFont={h1HeaderFont}
          mobileCtas={mobileCtas}
          buttonPlacementBelowSubcopy={buttonPlacementBelowSubcopy}
          bodyCopy1={bodyCopy1}
          bodyCopy1Font={bodyCopy1Font}
          bodyCopy2={bodyCopy2}
          bodyCopy2Font={bodyCopy2Font}
          bodyCopy3={bodyCopy3}
          bodyCopy3Font={bodyCopy3Font}
        />
        {renderImageComponent(
          heroImage?.sys?.contentType?.sys?.id ||
            heroImage?.contentfulContentType,
          heroImage
        )}
      </Grid>

      <Grid
        item
        xs={rightXS}
        sm={rightS}
        md={rightM}
        lg={rightL}
      >
        <ClientOnly>
          {embeddedScript &&
            embeddedScript.map((item, index) => (
              <Suspense fallback={<div />}>
                <EmbeddedScript
                  key={index}
                  scriptSource={item.scriptSource}
                  scriptInnerHtml={item.scriptInnerHtml}
                  scriptAppendToId={item.scriptAppendToId}
                  embeddedHtml={item.embeddedHtml}
                />
              </Suspense>
            ))}
        </ClientOnly>
      </Grid>
    </StyledHeroTemplateWrapper>
  );
};

export default HeroTemplateForm;
