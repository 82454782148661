import { styled } from '@mui/material';
import { Box, Accordion, Typography } from '@sezzle/sezzle-mui';

export const StyledCategoryAccordionWrapper = styled(Box)((props) => ({
  width: '100%',
}));

export const StyledCategoryAccordion = styled(Accordion)((props) => ({
  // Accordion header container
  '& .MuiButtonBase-root': {
    outlineColor: 'white',
    paddingLeft: 0,
    paddingRight: 0,
  },
  '& .MuiAccordionSummary-content': {
    marginTop: 0,
    marginBottom: 0,
  },
  // Accordion container
  '& .MuiAccordionDetails-root': {
    animation: 'fadein 0.35s ease-in',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
  },
  // Accordion content item text container
  '& .MuiListItem-root': {
    padding: 0,
  },
  // Accordion content Container
  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export const StyledCategoryAccordionHeaderText = styled(Typography)(
  (props) => ({
    fontSize: 16,
  })
);
