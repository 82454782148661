import axios, { AxiosRequestConfig } from 'axios';

export const sezzleHelpUrl = process.env.GATSBY_HELPDESK_URL;
export const sezzleApiUrl = process.env.GATSBY_SEZZLE_PAY_API_URL;

/**
 * get request to a url
 *
 * @param  {string} url       The url we are sending the request to
 *
 * @return {object}           The response data
 */

const commonHTTPConfig = {
  timeout: 30000,
};

const httpClient = axios.create(commonHTTPConfig);

export const getDefaultHeaders = async () => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  return headers;
};

export const postApi = async (
  url: string,
  data?: any,
  config?: AxiosRequestConfig | undefined
) => {
  const defaultHeaders = await getDefaultHeaders();
  httpClient.defaults.headers = defaultHeaders;

  return httpClient.post(url, data, {
    ...config,
  });
};

export const getApi = async (
  url: string,
  config?: AxiosRequestConfig | undefined
) => {
  const defaultHeaders = await getDefaultHeaders();
  httpClient.defaults.headers = defaultHeaders;

  return httpClient.get(url, {
    baseURL: sezzleApiUrl,
    ...config,
  });
};

