import React from 'react';
import { DesktopAppBarMenuProps } from './types';
import { ContentfulContentType } from '../../../types/enums';
import CTA from '../../../CTA';
import { CategoryMenuPopover } from './components/CategoryMenuPopover';
import {
  StyledDesktopMenuWrapper,
  StyledDesktopMenuNavigationButtonContainer,
  StyledDesktopMenuNavigationButtonList,
  StyledDesktopMenuSignInButtonContainer,
  StyledDesktopMenuSignInButtonList,
} from './styles';

const DesktopAppBarMenu = ({
  navigationLinks,
  ctaButtons,
}: DesktopAppBarMenuProps) => {
  const renderNavigationCTAOptions = (sectionType, fields, index) => {
    switch (sectionType || fields.contentfulContentType) {
      case ContentfulContentType.CTA:
        return <CTA fields={fields} />;
      case ContentfulContentType.LINKEDNAVIGATIONSTYLELINKS:
        return (
          <CategoryMenuPopover
            linkUrl={fields.linkUrl}
            displayText={fields.displayText}
            linkedContent={fields.linkedContent}
            index={index}
          />
        );
      default:
        console.log(`Section type not found: ${sectionType}`);
        return null;
    }
  };

  const renderSignInOptions = (sectionType, fields) => {
    switch (sectionType) {
      case ContentfulContentType.CTA:
        return (
          <CTA
            fields={fields}
            fullWidth={false}
          />
        );
      default:
        console.log(`Section type not found: ${sectionType}`);
        return null;
    }
  };

  return (
    <StyledDesktopMenuWrapper
      direction="row"
      justifyContent="flex-end"
    >
      <StyledDesktopMenuNavigationButtonContainer>
        {navigationLinks?.length !== 0 &&
          navigationLinks?.map((item, index) => (
            <StyledDesktopMenuNavigationButtonList key={index}>
              {renderNavigationCTAOptions(
                item?.sys?.contentType?.sys?.id || item?.contentfuLContentType,
                item,
                item?.index
              )}
            </StyledDesktopMenuNavigationButtonList>
          ))}
      </StyledDesktopMenuNavigationButtonContainer>

      <StyledDesktopMenuSignInButtonContainer>
        {ctaButtons?.length !== 0 &&
          ctaButtons?.map((item, index) => (
            <StyledDesktopMenuSignInButtonList key={index}>
              {renderSignInOptions(
                item?.sys?.contentType?.sys?.id || item?.contentfulContentType,
                item
              )}
            </StyledDesktopMenuSignInButtonList>
          ))}
      </StyledDesktopMenuSignInButtonContainer>
    </StyledDesktopMenuWrapper>
  );
};

export default DesktopAppBarMenu;
