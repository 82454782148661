import React from 'react';
import styled from 'styled-components';
import PageComponents from '../PageComponents';
import { PageSection as PageSectionProps } from './types/types';
import { setColor } from '../../utils/setColor';
import { ConditionalDisplayComponent } from '../ConditionalDisplayComponent';

interface SectionProps {
  backgroundColor: string;
}

const Section = styled.section<SectionProps>`
  background-color: ${(props: SectionProps) =>
    props.backgroundColor !== 'none'
      ? setColor(props.backgroundColor, props)
      : 'inherit'};

  div.display__large {
    display: block;

    @media (max-width: 768px) {
      display: none;
    }
  }

  div.display__small {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

const PageSection = ({ fields, pageContext }: PageSectionProps) => {
  const showSmallSection = fields?.showSmallSectionContent || false;
  const smallSectionsReturned = fields?.smallSections?.length > 0 || false;
  const fullWidth = 'fullWidthContainer';
  const containerWide = 'container wide';

  const sectionComponents = fields?.sections?.map((section, index) => {
    const fullWidthContainer =
      section.sys?.contentType?.sys?.id === fullWidth ||
      section.contentfulContentType === fullWidth
        ? null
        : containerWide;

    return (
      <div
        key={index}
        className={`${fullWidthContainer} ${showSmallSection && 'display__large'}`}
      >
        <PageComponents
          sectionType={
            section.sys?.contentType?.sys?.id || section?.contentfulContentType
          }
          section={section}
          pageContext={pageContext}
        />
      </div>
    );
  });

  const smallSectionComponents = fields?.smallSections?.map(
    (section, index) => {
      const fullWidthContainer =
        section.sys?.contentType?.sys?.id === fullWidth ||
        section.contentfulContentType === fullWidth
          ? null
          : containerWide;

      return (
        <div
          key={index}
          className={`${fullWidthContainer} display__small`}
        >
          <PageComponents
            sectionType={
              section.sys?.contentType?.sys?.id ||
              section?.contentfulContentType
            }
            section={section}
            pageContext={pageContext}
          />
        </div>
      );
    }
  );

  return (
    <Section backgroundColor={fields.backgroundColor}>
      {sectionComponents}
      {smallSectionsReturned && showSmallSection && smallSectionComponents}
    </Section>
  );
};

export default ConditionalDisplayComponent(PageSection);
