import { setCookie, getCookie } from '@library/shared-ui/utils/cookieUtils';

export const sendUTMInfo = () => {
  // window is undefined in gatsby build.
  // https://www.gatsbyjs.com/docs/debugging-html-builds/
  if (typeof window !== 'undefined') {
    // don't set anything if already set
    // we are going with first to refer approach
    const utmSource = getCookie('sz_utm_source')?.trim() || '';
    const utmMedium = getCookie('sz_utm_medium')?.trim() || '';
    const utmCampaign = getCookie('sz_utm_campaign')?.trim() || '';
    const utmTerm = getCookie('sz_utm_term')?.trim() || '';
    const utmContent = getCookie('sz_utm_content')?.trim() || '';
    if (utmSource || utmMedium || utmCampaign || utmTerm || utmContent) {
      return;
    }
    const query = window.location.search;
    const search = new URLSearchParams(query);

    const UTMSource = search.get('utm_source');
    const UTMMedium = search.get('utm_medium');
    const UTMCampaign = search.get('utm_campaign');
    const UTMTerm = search.get('utm_term');
    const UTMContent = search.get('utm_content');

    if (UTMSource) {
      setCookie('sz_utm_source', UTMSource);
    }
    if (UTMMedium) {
      setCookie('sz_utm_medium', UTMMedium);
    }
    if (UTMCampaign) {
      setCookie('sz_utm_campaign', UTMCampaign);
    }
    if (UTMTerm) {
      setCookie('sz_utm_term', UTMTerm);
    }
    if (UTMContent) {
      setCookie('sz_utm_content', UTMContent);
    }
  }
};
