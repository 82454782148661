/*
**
We want to be able to read the sezzle__locale cookie if it has been set
**
*/
import Cookies from 'universal-cookie';

const cookies = new Cookies();

/* Get cookie */
export const getCookieDomain = (): string => {
  if (typeof document === 'undefined' || !document) {
    return '';
  }
  // the leading dot makes sure all subdomains share this cookie
  // see: RFC 2109
  return `.${document.location.hostname.split('.').splice(-2).join('.')}`;
};

interface GetCookie {
  (cookieKey: string): string;
}

export const getCookie: GetCookie = (cookieKey) => {
  return cookies.get(cookieKey);

  // let cookieValue = '';
  // if (typeof document === 'undefined' || !document) {
  //   return;
  // }
  // const cookiesArray = document.cookie.split(';');
  // // eslint-disable-next-line no-plusplus
  // for (let i = 0; i < cookiesArray.length; i++) {
  //   const cookie = cookiesArray[i].trim();
  //   if (cookie && cookie.startsWith(`${cookieKey}=`)) {
  //     cookieValue = cookie.slice(cookieKey.length + 1);
  //     break;
  //   }
  // }
  // // eslint-disable-next-line consistent-return
  // return cookieValue;
};

/* Set/Unset cookie */
interface SetCookie {
  (cookieKey: string, cookieValue: string, expiryDate?: Date): void;
}

interface UnsetCookie {
  (cookieKey: string): void;
}

export const unsetCookie: UnsetCookie = (cookieKey) => {
  if (typeof document === 'undefined' || !document) {
    return;
  }
  document.cookie = `${cookieKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
  document.cookie = `${cookieKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/`;
};

export const setCookie: SetCookie = (
  cookieKey: string,
  cookieValue: string,
  expiryDate?: Date
) => {
  if (getCookie(cookieKey) !== '') unsetCookie(cookieKey);

  const expiryOption = expiryDate ? { expiryDate } : {};
  const options = {
    path: '/',
    domain: getCookieDomain(),
    ...expiryOption,
  };

  cookies.set(cookieKey, cookieValue, options);
};
