/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import { ImageLinkWithText as ImageLinkWithTextProps } from '../../../../../ImageLinkWithText/types/types';
import Image from '../../../../../Image';

const StyledDropDownImageLinkWithText = styled.div`
  display: flex;
  width: 100%;

  a {
    font-size: 0.75rem;
    text-decoration: none !important;
    color: ${(props) => props.theme.primary};
    display: inline-flex;
    font-family: ${(props) => props.theme.bodyFont};

    &:hover {
      text-decoration: underline !important;
      color: ${(props) => props.theme.secondary};
    }
  }

  .gatsby-image-wrapper {
    width: 50px;
  }

  img {
    max-height: 50px;
    margin-right: 1rem;
  }

  span {
    margin: auto 0 auto 1rem;
  }
`;

const ImageLinkWithTextDropdownMenu = ({
  url,
  image,
  displayText,
}: ImageLinkWithTextProps) => {
  return (
    <StyledDropDownImageLinkWithText>
      <a
        href={url?.url || url}
        target="_blank"
        rel="noreferrer"
      >
        <Image fields={image} />
        <span>{displayText}</span>
      </a>
    </StyledDropDownImageLinkWithText>
  );
};

export default ImageLinkWithTextDropdownMenu;
