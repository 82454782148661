/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import renderCollapsibleOptions from '../renderCollapsibleOptions';
import { FooterLinks as FooterLinksProps } from './types/types';

const ExpandedLists = styled.div`
  h2.szl__accordion__heading {
    font-family: ${(props: any) => `${props.theme.bodyFont}`} !important;
    font-weight: 600;
    font-size: 1rem;
    padding: 0 0 1.125rem;
    margin: 1rem 0 0 0;
  }
`;

const FooterLinks = (props: FooterLinksProps) => {
  const { listHeader, list } = props;
  return (
    <ExpandedLists>
      <h2 className="szl__accordion__heading">{listHeader}</h2>
      <ul>
        {list?.length !== 0 &&
          list?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>
              {renderCollapsibleOptions(
                item?.sys?.contentType?.sys?.id || item?.contentfulContentType,
                item
              )}
            </li>
          ))}
      </ul>
    </ExpandedLists>
  );
};

export default FooterLinks;
