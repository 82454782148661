import React from 'react';
import Image from './Image';
import ImageLink from './ImageLink';
import ImageLinkWithText from './ImageLinkWithText';
import NavBar from './SubNavBar';
import SetOf from './SetOf';
import { ContentfulContentType } from './types/enums';

const Carousel = React.lazy(() => import('./Carousel'));
const AutoCarousel = React.lazy(() => import('./AutoCarousel'));

export const renderCallOutOptions = (sectionType, fields) => {
  switch (sectionType) {
    case ContentfulContentType.CAROUSEL:
      switch (fields?.shouldAutoSlide) {
        case true:
          return (
            <AutoCarousel
              slides={fields.slides}
              mobileVisibleSlides={fields.mobileVisibleSlides}
              desktopVisibleSlides={fields.desktopVisibleSlides}
              delayBetweenTransitions={fields.delayBetweenTransitions}
            />
          );
        case false:
          return (
            <Carousel
              slides={fields.slides}
              mobileVisibleSlides={fields.mobileVisibleSlides}
              desktopVisibleSlides={fields.desktopVisibleSlides}
            />
          );
        default:
          return (
            <Carousel
              slides={fields.slides}
              mobileVisibleSlides={fields.mobileVisibleSlides}
              desktopVisibleSlides={fields.desktopVisibleSlides}
            />
          );
      }
    case ContentfulContentType.IMAGE:
      return <Image fields={fields} />;
    case ContentfulContentType.IMAGELINK:
      return (
        <ImageLink
          node_locale={fields.node_locale}
          shouldTextShow={fields.shouldTextShow}
          url={fields.imageUrl || fields.url}
          image={fields.image}
          textAlignment={fields.textAlignment}
          assistiveText={fields.assistiveText}
        />
      );
    case ContentfulContentType.IMAGELINKWITHTEXT:
      return (
        <ImageLinkWithText
          url={fields.imageUrl}
          image={fields.image}
          textAlignment={fields.textAlignment}
          displayText={fields.displayText}
        />
      );
    case ContentfulContentType.NAVBAR:
      return <NavBar fields={fields} />;
    case ContentfulContentType.SETOF:
      return <SetOf fields={fields} />;
    default:
      console.log(`Section type not found: ${sectionType}`);
      return null;
  }
};

export default renderCallOutOptions;
