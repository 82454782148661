import { styled } from '@mui/material';
import { AppBar, Toolbar, Box } from '@sezzle/sezzle-mui';

export const StyledAppBar = styled(AppBar)(() => ({
  '&.MuiPaper-root': {
    boxShadow: 'none',
    borderBottom: '1px solid #E0E0E0',
    borderRadius: 0,
    padding: '13px 0',
  },
}));

export const StyledToolBar = styled(Toolbar)(() => ({
  justifyContent: 'center',
}));

export const StyledAppBarContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: 1600,
  justifyContent: 'space-between',

  '.mobile-only': {
    display: 'block',
  },

  [theme.breakpoints.up('lg')]: {
    justifyContent: 'center',

    '.mobile-only': {
      display: 'none',
    },
  },
}));

export const StyledAppBarWebButtonsContentContainer = styled(Box)((props) => ({
  display: 'none',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: 30,

  [props.theme.breakpoints.up('lg')]: {
    display: 'flex',
  },
}));

export const StyledAppBarLogoWrapper = styled(Box)((props) => ({
  width: '15%',
  [props.theme.breakpoints.down('sm')]: { width: '50%' },
  [props.theme.breakpoints.down('xs')]: { width: '50%' },
  alignItems: 'center',
  height: 'auto',
}));

export const StyledAppBarLogoContainer = styled(Box)((props) => ({
  '& a': {
    display: 'block',
    backgroundRepeat: 'no-repeat',
    zIndex: 1000,
    backgroundPosition: 'left center',
    backgroundSize: 130,
    [props.theme.breakpoints.down('sm')]: {
      backgroundSize: '100%',
      backgroundPosition: 'center',
    },
    [props.theme.breakpoints.down('xs')]: {
      backgroundSize: '100%',
      backgroundPosition: 'center',
    },
    [props.theme.breakpoints.down('md')]: { backgroundPosition: 'center' },
  },
  '.gatsby-image-wrapper': {
    height: 75,
  },
}));
