import React, { useEffect } from 'react';
import { EmbeddedScript as EmbeddedScriptProps } from './types/types';

declare let window: any;

const EmbeddedScript = (props: EmbeddedScriptProps) => {
  const { scriptSource, scriptInnerHtml, scriptAppendToId, embeddedHtml } =
    props;

  useEffect(() => {
    const script = document.createElement('script');
    if (scriptSource) {
      script.src = `${scriptSource}`;
    }
    script.async = true;

    if (scriptInnerHtml) script.innerHTML = scriptInnerHtml.scriptInnerHtml;

    if (scriptAppendToId) {
      document.getElementById(`${scriptAppendToId}`).appendChild(script);
    } else {
      document.body.appendChild(script);
    }

    if (window.Trustpilot) {
      let widgets = document.querySelectorAll('.trustpilot-widget');
      widgets.forEach((widget) => {
        window.Trustpilot.loadFromElement(widget);
      });
    }
  }, []);

  return (
    <>
      {embeddedHtml && (
        <div
          dangerouslySetInnerHTML={{
            __html: embeddedHtml?.embeddedHtml || embeddedHtml,
          }}
        />
      )}
    </>
  );
};

export default EmbeddedScript;
