import React from 'react';
import CollapsibleList from './CollapsibleList';
import CTA from './CTA';
import ImageLink from './ImageLink';
import SetOf from './SetOf';
import Wrapped from './Wrapped';
import { ContentfulContentType } from './types/enums';

const AutoCarousel = React.lazy(() => import('./AutoCarousel'));
const Carousel = React.lazy(() => import('./Carousel'));

export const renderContentSections = (content) => {
  const categorizedContent =
    content?.sys?.contentType?.sys?.id || content?.contentfulContentType;
  switch (categorizedContent) {
    case ContentfulContentType.CAROUSEL:
      switch (content?.shouldAutoSlide) {
        case true:
          return (
            <AutoCarousel
              slides={content.slides}
              mobileVisibleSlides={content.mobileVisibleSlides}
              desktopVisibleSlides={content.desktopVisibleSlides}
              delayBetweenTransitions={content.delayBetweenTransitions}
            />
          );
        case false:
          return (
            <Carousel
              slides={content.slides}
              mobileVisibleSlides={content.mobileVisibleSlides}
              desktopVisibleSlides={content.desktopVisibleSlides}
            />
          );
        default:
          return (
            <Carousel
              slides={content.slides}
              mobileVisibleSlides={content.mobileVisibleSlides}
              desktopVisibleSlides={content.desktopVisibleSlides}
            />
          );
      }
    case ContentfulContentType.COLLAPSIBLELIST:
      return (
        <CollapsibleList
          listHeader={content.listHeader}
          headerLevel={content.headerLevel}
          list={content.list}
        />
      );
    case ContentfulContentType.CTA:
      return <CTA fields={content} />;
    case ContentfulContentType.IMAGELINK:
      return (
        <ImageLink
          node_locale={content.node_locale}
          shouldTextShow={content.shouldTextShow}
          url={content.imageUrl || content.url}
          image={content.image}
          textAlignment={content.textAlignment}
          assistiveText={content.assistiveText}
        />
      );
    case ContentfulContentType.SETOF:
      return <SetOf fields={content} />;
    case ContentfulContentType.WRAPPED:
      return <Wrapped fields={content} />;
    default:
      return null;
  }
};
