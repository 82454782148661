/* eslint-disable react/require-default-props */
import React from 'react';

interface OwnProps {
  /** page title */
  pageTitle: string;
  /** page description */
  pageDescription: string;
  /** domain url */
  domain: string;
  /** security */
  noIndex?: boolean;
  noFollow?: boolean;
  locale?: string;
}

const SEO = (props: OwnProps) => {
  const {
    pageTitle,
    pageDescription,
    domain,
    noIndex,
    noFollow,
    locale = 'en-US',
  } = props;

  const ogLocale = React.useMemo(() => {
    return locale.replace('-', '_');
  }, [locale]);

  const htmlLang = React.useMemo(() => {
    return locale.substring(0, 2);
  }, [locale]);

  return (
    <>
      <html lang={htmlLang} />
      <title>{pageTitle}</title>
      <meta
        name="description"
        content={pageDescription}
      />
      <meta
        property="og:locale"
        content={ogLocale}
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:image"
        content="https://media.sezzle.com/branding/2.0/sezzle-og-image.jpg"
      />
      <meta
        property="og:title"
        content="Sezzle: Buy Now, Pay Later. 0% Interest."
      />
      <meta
        property="og:description"
        content="Buy from your favorite stores today, and split up the cost into four interest-free payments."
      />
      <meta
        property="og:url"
        content={domain}
      />
      <meta
        property="og:site_name"
        content="Sezzle"
      />
      <meta
        name="twitter:card"
        content="summary"
      />
      <meta
        name="twitter:description"
        content="Buy from your favorite stores today, and split up the cost into four interest-free payments."
      />
      <meta
        name="twitter:image"
        content="https://media.sezzle.com/branding/2.0/sezzle-og-image.jpg"
      />
      <meta
        name="twitter:title"
        content="Sezzle: Buy Now, Pay Later. 0% Interest."
      />
      <meta
        name="twitter:site"
        content="@sezzleinc"
      />
      <meta
        name="twitter:creator"
        content="@sezzleinc"
      />
      <meta
        name="fo-verify"
        content="0ab3c59e-9b79-4cb3-b5df-1833518fd597"
      />
      {noIndex && (
        <meta
          name="robots"
          content="noindex"
        />
      )}
      {noFollow && (
        <meta
          name="robots"
          content="nofollow"
        />
      )}
    </>
  );
};

export default SEO;
