import React from 'react';
import styled from 'styled-components';
import renderSideBySideOptions from '../renderSideBySideOptions';
import { SideBySideAndStacked as SideBySideAndStackedProps } from './types/types';
import { setColor } from '../../utils/setColor';

interface StyledProps {
  backgroundColor: any;
}

export const SideBySideStyled = styled.div<Partial<StyledProps>>`
  padding-left: 1rem;
  padding-right: 1rem;

  .row {
    background-color: ${(props: any) =>
      props.backgroundColor !== 'none'
        ? setColor(props.backgroundColor, props)
        : 'inherit'};
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    max-height: 100%;
  }

  div.row {
    padding: 0;
  }

  .right {
    text-align: right;
  }

  div.col-sm-12 {
    padding: 0;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  img {
    width: auto;

    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }
`;

type Props = SideBySideAndStackedProps & StyledProps;

const SideBySideAndStacked = ({
  backgroundColor,
  sectionId,
  imageSide,
  image,
  content,
}: Props) => {
  return (
    <SideBySideStyled
      data-content-type="sideBySideAndStacked"
      backgroundColor={backgroundColor}
      id={sectionId}
    >
      <div
        className={`row ${imageSide === 'right' ? 'row__reverse--small' : ''}`}
      >
        <div
          className={`col-xs-12 col-sm-12 col-md-6 col-lg-${imageSide === 'right' ? '7' : '5'}
        align--center ${imageSide === 'right' ? 'center' : ''}`}
        >
          {imageSide === 'left' ? (
            <div>
              {renderSideBySideOptions(
                image?.sys?.contentType?.sys?.id ||
                  image?.contentfulContentType,
                image
              )}
            </div>
          ) : (
            renderSideBySideOptions(
              content?.sys?.contentType?.sys?.id ||
                content?.contentfulContentType,
              content
            )
          )}
        </div>
        <div
          className={`col-xs-12 col-sm-12 col-md-6 col-lg-${imageSide === 'left' ? '7' : '5'}
        align--center ${imageSide === 'left' ? 'center' : 'right'}`}
        >
          {imageSide === 'right' ? (
            <div>
              {renderSideBySideOptions(
                image?.sys?.contentType?.sys?.id ||
                  image?.contentfulContentType,
                image
              )}
            </div>
          ) : (
            renderSideBySideOptions(
              content?.sys?.contentType?.sys?.id ||
                content?.contentfulContentType,
              content
            )
          )}
        </div>
        <div />
      </div>
    </SideBySideStyled>
  );
};

export default SideBySideAndStacked;
