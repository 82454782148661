import React from 'react';
import styled from 'styled-components';
import renderWrappedOptions from '../renderWrappedOptions';
import { setColor } from '../../utils/setColor';

interface OwnProps {
  fields: any;
}

interface StyleProps {
  backgroundColor: string;
  borderColor: string;
}

export const Wrapper = styled.div<StyleProps>`
  background-color: ${(props: any) =>
    props.backgroundColor !== null && props.backgroundColor !== undefined
      ? setColor(props.backgroundColor, props)
      : 'inherit'};
  border: ${(props: any) =>
    props.borderColor !== null && props.borderColor !== undefined
      ? `2px solid ${setColor(props.borderColor, props)}`
      : 'none'};

  &.padding__vertical {
    &--1x {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    &--2x {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    &--3x {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  &.padding__horizontal {
    &--1x {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &--2x {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &--3x {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  &.wrapper--alignment {
    &-center {
      text-align: center;
    }
    &-left {
      text-align: left;
    }
    &-right {
      text-align: right;
    }
  }

  span.block {
    display: block;
  }

  span.inline {
    display: inline;
    p,
    a,
    div {
      display: inline;
    }
  }

  span.padding__horizontal {
    &--1x {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &--2x {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &--3x {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  span.padding__vertical {
    &--1x {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    &--2x {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    &--3x {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
`;

const Wrapped = (props: OwnProps) => {
  const { fields } = props;

  return (
    <Wrapper
      className={`wrapper wrapper__content
      ${fields.horizontalContainerPadding ? fields.horizontalContainerPadding : 'padding__horizontal--0'}
      ${fields.containerPadding ? fields.containerPadding : 'padding__vertical--0'}
    wrapper--alignment-${fields.alignment !== null ? fields.alignment : 'center'}`}
      backgroundColor={fields.backgroundColor}
      borderColor={fields.borderColor}
    >
      <div className={`${fields.contentNewLine}`}>
        {(fields.wrappedContent || fields.content)?.map((item, index) => (
          <span
            key={index}
            className={`${
              fields.contentNewLine === true
                ? `block ${fields.verticalElementPadding ? fields.verticalElementPadding : 'padding__vertical--0'}
            ${
              fields.horizontalElementPadding
                ? fields.horizontalElementPadding
                : 'padding__horizontal--0'
            }`
                : `inline ${
                    fields.verticalElementPadding
                      ? fields.verticalElementPadding
                      : 'padding__vertical--0'
                  }
                ${
                  fields.horizontalElementPadding
                    ? fields.horizontalElementPadding
                    : 'padding__horizontal--0'
                }`
            }`}
          >
            {renderWrappedOptions(
              item.sys?.contentType?.sys?.id || item?.contentfulContentType,
              item
            )}
          </span>
        ))}
      </div>
    </Wrapper>
  );
};

export default Wrapped;
