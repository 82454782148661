import React from 'react';
import CTA from './CTA';
import DownloadCTA from './CTA/DownloadCTA';
import Paragraph from './Paragraph';
import Text from './Text';
import { ContentfulContentType } from './types/enums';

export const renderCollapsibleOptions = (sectionType, fields) => {
  switch (sectionType) {
    case ContentfulContentType.CTA:
      return <CTA fields={fields} />;
    case ContentfulContentType.DOWNLOADCTA:
      return <DownloadCTA fields={fields} />;
    case ContentfulContentType.PARAGRAPH:
      return (
        <Paragraph
          paragraph={fields.paragraph}
          bodyFontSize={fields.bodyFontSize}
        />
      );
    case ContentfulContentType.TEXT:
      return <Text {...fields} />;
    default:
      console.log(`Section type not found: ${sectionType}`);
      return null;
  }
};

export default renderCollapsibleOptions;
