import { styled, Stack } from '@mui/material';
import { Grid, List } from '@sezzle/sezzle-mui';

export const StyledDesktopMenuWrapper = styled(Stack)((props) => ({
  marginLeft: props.theme.spacing(5),
}));

export const StyledDesktopMenuNavigationButtonContainer = styled(Stack)(
  (props) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: props.theme.spacing(3),
  })
);

export const StyledDesktopMenuNavigationButtonList = styled(List)((props) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledDesktopMenuSignInButtonContainer = styled(Grid)((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: props.theme.spacing(2),
  marginLeft: props.theme.spacing(4),
}));

export const StyledDesktopMenuSignInButtonList = styled(List)((props) => ({
  minWidth: 104,
}));
