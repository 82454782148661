// eslint-disable-next-line class-methods-use-this
export function initZendeskWidget(zendeskKey) {
  // eslint-disable-next-line func-names
  (function (d, src) {
    const t = d.scripts[d.scripts.length - 1];
    const s = d.createElement('script');
    s.id = 'ze-snippet';
    s.async = true;
    s.src = src;
    // eslint-disable-next-line
    s.onload = (s as any).onreadystatechange = function () {};
    if (t) t.parentElement.insertBefore(s, t.nextSibling);
  })(document, `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`);
}
