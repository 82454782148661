import React from 'react';
import HeroSection from '../HeroSection';
import PageSection from '../PageSection';
import { ContentfulContentType } from '../types/enums';
import { PageSectionComponents as PageSectionComponentsProps } from './types/types';

const PageSectionComponents = ({
  sectionType,
  section,
  pageContext,
}: PageSectionComponentsProps) => {
  const renderComponent = () => {
    switch (sectionType) {
      case ContentfulContentType.HEROSECTION:
        return (
          <HeroSection
            callOut={section.callOut}
            subNavBar={section.subNavBar}
            hero={section.hero}
          />
        );
      case ContentfulContentType.PAGESECTION:
        return (
          <PageSection
            fields={section}
            pageContext={pageContext}
            shouldDisplay={section.shouldDisplay}
          />
        );
      default:
        console.warn(`Section type not found: ${sectionType}`);
        return (
          <div data-content-type="not-found">Unknown Page Component Type</div>
        );
    }
  };
  return <>{renderComponent()}</>;
};

export default PageSectionComponents;
