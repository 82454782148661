import React from 'react';
import CollapsibleList from './CollapsibleList';
import CTA from './CTA';
import DownloadCTA from './CTA/DownloadCTA';
import Text from './Text';
import { ContentfulContentType } from './types/enums';
import Paragraph from './Paragraph';

export const renderCTAOptions = (sectionType, fields, fullWidth = false) => {
  switch (sectionType) {
    case ContentfulContentType.TEXT:
      return <Text {...fields} />;
    case ContentfulContentType.PARAGRAPH:
      return (
        <Paragraph
          paragraph={fields.paragraph}
          bodyFontSize={fields.bodyFontSize}
        />
      );
    case ContentfulContentType.COLLAPSIBLELIST:
      return (
        <CollapsibleList
          listHeader={fields.listHeader}
          headerLevel={fields.headerLevel}
          list={fields.list}
        />
      );
    case ContentfulContentType.CTA:
      return (
        <CTA
          fields={fields}
          fullWidth={fullWidth}
        />
      );
    case ContentfulContentType.DOWNLOADCTA:
      return <DownloadCTA fields={fields} />;
    default:
      console.log(`Section type not found: ${sectionType}`);
      return null;
  }
};
