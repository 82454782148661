import React from 'react';
import styled from 'styled-components';
import { renderCTAOptions } from '../renderCTAOptions';
import { Headline as HeadlineProps } from './types/types';

export const Header = styled.div`
  margin-bottom: 1rem;

  h2 {
    margin-bottom: 0.5rem;
  }

  .centered {
    text-align: center;
    display: block;
  }

  .left {
    text-align: left;
    display: block;
  }

  .right {
    text-align: right;
    display: block;
  }

  .justified {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
  }

  p {
    margin: 0;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 1rem;
  }
`;

const Headline = ({
  contentfulid,
  alignment,
  header,
  subcopy,
  subCopySmall,
  link,
}: Partial<HeadlineProps>) => {
  return (
    <Header
      data-component-type="headline"
      {...(contentfulid ? { id: `${contentfulid}` } : null)}
    >
      <div className={alignment?.toLowerCase() || 'centered'}>
        <h2>{header}</h2>
        {subcopy && alignment?.toLowerCase() !== 'justified' ? (
          <p
            className={
              `${alignment?.toLowerCase()} body__copy--small visible--small-opp` ||
              'centered body__copy--small visible--small-opp'
            }
          >
            {subcopy}
          </p>
        ) : null}
        {subCopySmall && alignment.toLowerCase() !== 'justified' ? (
          <p
            className={
              `${alignment.toLowerCase()} body__copy--small visible--small` ||
              'centered body__copy--small visible--small'
            }
          >
            {subCopySmall}
          </p>
        ) : null}
        {link && link
          ? renderCTAOptions(
              link?.sys?.contentType?.sys?.id || link?.contentfulContentType,
              link,
              true
            )
          : null}
      </div>
      {subcopy && alignment?.toLowerCase() === 'justified' ? (
        <p
          className={
            `${alignment?.toLowerCase()} body__copy--small visible--small-opp` ||
            'centered body__copy--small visible--small-opp'
          }
        >
          {subcopy}
        </p>
      ) : null}
      {subCopySmall && alignment.toLowerCase() === 'justified' ? (
        <p
          className={
            `${alignment.toLowerCase()} body__copy--small visible--small` ||
            'centered body__copy--small visible--small'
          }
        >
          {subCopySmall}
        </p>
      ) : null}
    </Header>
  );
};

export default Headline;
