import React from 'react';
import styled from 'styled-components';

interface OwnProps {
  fields: any;
  maxWidth: string;
  maxWidthSmall: string;
}

const StyledPicture = styled.picture<Partial<OwnProps>>`
  img {
    max-width: ${(props: any) =>
      props.maxWidth !== undefined ? `${props.maxWidth}px` : '100%'};
    height: auto;

    @media (max-width: 768px) {
      max-width: ${(props: any) =>
        props.maxWidthSmall !== undefined
          ? `${props.maxWidthSmall}px`
          : '100%'};
      height: auto;
    }
  }

  img.img__padding {
    &--0 {
      padding: 0;
    }

    &--1x {
      padding: 1rem;
    }

    &--2x {
      padding: 2rem;
    }

    &--3x {
      padding: 3rem;
    }

    @media (max-width: 768px) {
      &--0-sm {
        padding: 0 !important;
      }

      &--1x-sm {
        padding: 1rem !important;
      }

      &--2x-sm {
        padding: 2rem !important;
        margin-bottom: 0 !important;
      }

      &--3x-sm {
        padding: 3rem !important;
        margin-bottom: 0 !important;
      }
    }
  }
`;

const PreviewImage = ({ fields }: Partial<OwnProps>) => {
  return (
    <StyledPicture
      maxWidth={fields?.maxWidth}
      maxWidthSmall={fields?.maxWidthSmall}
    >
      {(fields?.imageSm?.fields?.file?.url || fields?.imageSm?.file?.url) && (
        <source
          media="(max-width: 768px)"
          srcSet={`https:${fields?.imageSm?.fields?.file?.url || fields?.imageSm?.file?.url}`}
        />
      )}
      <source
        media="(min-width: 769px)"
        srcSet={
          fields?.image?.fields?.file?.url ||
          fields?.image?.file?.url ||
          fields?.file?.url
            ? `https:${fields?.image?.fields?.file.url || fields?.image?.file?.url || fields?.file?.url}`
            : `https:${fields?.imageLg?.fields?.file?.url || fields?.imageLg?.file?.url}`
        }
      />
      <img
        {...(fields?.paddingSmall === undefined &&
        fields?.paddingLarge === undefined
          ? null
          : {
              className: `${
                fields?.paddingSmall
                  ? `img__padding--${fields?.paddingSmall}-sm`
                  : ''
              } ${
                fields?.paddingLarge
                  ? `img__padding--${fields?.paddingLarge}`
                  : ''
              }`,
            })}
        src={
          fields?.image?.fields?.file?.url ||
          fields?.image?.file?.url ||
          fields?.file?.url
            ? `https:${fields?.image?.fields?.file?.url || fields?.image?.file?.url || fields?.file?.url}`
            : `https:${fields?.imageLg?.fields?.file?.url || fields?.imageLg?.file?.url}`
        }
        alt={fields?.alternativeText || ''}
      />
    </StyledPicture>
  );
};

export default PreviewImage;
