import React, { useMemo } from 'react';
import styled from 'styled-components';
import { TagWrapper as TagWrapperProps } from './types/types';

const StyledH1Text = styled.h1<{ $fontSize?: string; $textAlign?: string }>`
  font-size: ${(props) => props.$fontSize};
  text-align: ${(props) => props.$textAlign};
`;

const StyledH2Text = styled.h2<{ $fontSize?: string; $textAlign?: string }>`
  font-size: ${(props) => props.$fontSize};
  text-align: ${(props) => props.$textAlign};
`;

const StyledH3Text = styled.h3<{ $fontSize?: string; $textAlign?: string }>`
  font-size: ${(props) => props.$fontSize};
  text-align: ${(props) => props.$textAlign};
`;

const StyledH4Text = styled.h4<{ $fontSize?: string; $textAlign?: string }>`
  font-size: ${(props) => props.$fontSize};
  text-align: ${(props) => props.$textAlign};
`;

const StyledH5Text = styled.h5<{ $fontSize?: string; $textAlign?: string }>`
  font-size: ${(props) => props.$fontSize};
  text-align: ${(props) => props.$textAlign};
`;

const StyledH6Text = styled.h6<{ $fontSize?: string; $textAlign?: string }>`
  font-size: ${(props) => props.$fontSize};
  text-align: ${(props) => props.$textAlign};
`;

const StyledParagraphText = styled.p<{
  $fontSize?: string;
  $textAlign?: string;
}>`
  text-align: ${(props) => props.$textAlign};
  font-size: ${(props) => props.$fontSize};
  &.body__copy {
    &--large {
      font-size: ${(props) => props.$fontSize || '1.125rem'};
      line-height: 32px;
      font-weight: normal;
      font-style: normal;
    }

    &--small {
      font-size: ${(props) => props.$fontSize || '1rem'};
      line-height: 24px;
      font-weight: normal;
      font-style: normal;
    }
  }
`;

const StyledSpanText = styled.span<{ $fontSize?: string; $textAlign?: string }>`
  font-size: ${(props) => props.$fontSize};
  text-align: ${(props) => props.$textAlign};
`;

const StyledDivText = styled.div<{ $fontSize?: string; $textAlign?: string }>`
  font-size: ${(props) => props.$fontSize};
  text-align: ${(props) => props.$textAlign};
`;

const getStyledElement = (tag: string) => {
  switch (tag) {
    case 'h1':
      return StyledH1Text;
    case 'h2':
      return StyledH2Text;
    case 'h3':
      return StyledH3Text;
    case 'h4':
      return StyledH4Text;
    case 'h5':
      return StyledH5Text;
    case 'h6':
      return StyledH6Text;
    case 'p':
      return StyledParagraphText;
    case 'span':
      return StyledSpanText;
    case 'div':
      return StyledDivText;
  }
};

const TagWrapper: React.FC<
  TagWrapperProps & React.ComponentProps<'h1' | 'p' | 'span'>
> = ({ tag, fontSize, textAlign, children, ...props }: TagWrapperProps) => {
  const StyledElement = useMemo(() => {
    return getStyledElement(tag);
  }, [tag]);

  return (
    <StyledElement
      $fontSize={fontSize}
      $textAlign={textAlign}
      {...props}
    >
      {children}
    </StyledElement>
  );
};

export default TagWrapper;
