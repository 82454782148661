import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import renderCollapsibleOptions from '../renderCollapsibleOptions';
import { CollapsibleList as CollapsibleListProps } from './types/types';

export const CollapsibleList = styled(Accordion)`
  .szl__accordion__heading {
    font-family: ${(props: any) => `${props.theme.bodyFont}`} !important;
    font-weight: 600;
    font-size: 1rem;
    padding: 0 0 1.125rem;
    margin: 0;
    color: ${(props: any) => `${props.theme.primary}`} !important;
  }

  .accordion__panel {
    animation: fadein 0.35s ease-in;
  }
`;

const Collapsible = ({
  listHeader,
  list,
  headerLevel,
}: CollapsibleListProps) => {
  const [open, setOpenStatus] = useState(false);

  return (
    <CollapsibleList
      allowZeroExpanded
      allowMultipleExpanded
      onChange={() => setOpenStatus(!open)}
    >
      <AccordionItem>
        <AccordionItemHeading
          aria-level={headerLevel || 2}
          className="szl__accordion__heading"
        >
          <AccordionItemButton aria-label={listHeader}>
            {open ? `${listHeader} ^` : `${listHeader} >`}
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ul>
            {list?.length !== 0 &&
              list?.map((item, index) => (
                <li key={index}>
                  {renderCollapsibleOptions(
                    item?.sys?.contentType?.sys?.id ||
                      item?.contentfulContentType,
                    item
                  )}
                </li>
              ))}
          </ul>
        </AccordionItemPanel>
      </AccordionItem>
    </CollapsibleList>
  );
};

export default Collapsible;
