import { styled } from '@mui/material';
import { Grid } from '@sezzle/sezzle-mui';

export const StyledHeroTemplateWrapper = styled(Grid)((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: props.theme.spacing(3),
}));
