import sezzleTheme from '../../../../../packages/website/src/templates/Page/themes';

import { styled as styledMUI } from '@mui/material';
import styled from 'styled-components';
import { Link as MuiLink } from '@sezzle/sezzle-mui';
import { Link as GatsbyLink } from 'gatsby';

export const StyledGatsbyLink = styled(GatsbyLink)((props) => ({
  fontFamily: sezzleTheme.bodyFont,
  textDecoration: 'none',
  color: sezzleTheme.textColor,
  '&:hover': {
    color: sezzleTheme.linkColorHover,
    textDecoration: 'underline',
  },
}));

export const StyledMuiLink = styledMUI(MuiLink)((props) => ({
  fontFamily: sezzleTheme.bodyFont,
  textDecoration: 'none',
  color: sezzleTheme.textColor,
  '&:hover': {
    color: sezzleTheme.linkColorHover,
    textDecoration: 'underline',
  },
}));
