import React from 'react';
import Card from './Card';
import CollapsibleList from './CollapsibleList';
import CTA from './CTA';
import DownloadCTA from './CTA/DownloadCTA';
import Headline from './Headline';
import Image from './Image';
import ImageLink from './ImageLink';
import ImageLinkWithText from './ImageLinkWithText';
import Paragraph from './Paragraph';
import Text from './Text';
import { ContentfulContentType } from './types/enums';

export const renderComponent = (sectionType, fields) => {
  switch (sectionType) {
    case ContentfulContentType.CARD:
      return (
        <Card
          top={fields.top}
          link={fields.link}
          title={fields.title}
          subtitle={fields.subtitle}
          longSubTitle={fields.longSubTitle}
          borderColor={fields.borderColor}
          alignment={fields.alignment}
          template={fields.template}
          backgroundColor={fields.backgroundColor}
        />
      );
    case ContentfulContentType.COLLAPSIBLELIST:
      return (
        <CollapsibleList
          listHeader={fields.listHeader}
          headerLevel={fields.headerLevel}
          list={fields.list}
        />
      );
    case ContentfulContentType.CTA:
      return <CTA fields={fields} />;
    case ContentfulContentType.DOWNLOADCTA:
      return <DownloadCTA fields={fields} />;
    case ContentfulContentType.HEADLINE:
      return (
        <Headline
          contentfulid={fields.contentfulid}
          header={fields.header}
          alignment={fields.alignment}
          subcopy={fields.subcopy}
          subCopySmall={fields.subCopySmall}
          link={fields.link}
        />
      );
    case ContentfulContentType.IMAGE:
      return <Image fields={fields} />;
    case ContentfulContentType.IMAGELINK:
      return (
        <ImageLink
          node_locale={fields.node_locale}
          shouldTextShow={fields.shouldTextShow}
          url={fields.imageUrl || fields.url}
          image={fields.image}
          textAlignment={fields.textAlignment}
          assistiveText={fields.assistiveText}
        />
      );
    case ContentfulContentType.IMAGELINKWITHTEXT:
      return (
        <ImageLinkWithText
          url={fields.imageUrl}
          image={fields.image}
          textAlignment={fields.textAlignment}
          displayText={fields.displayText}
        />
      );
    case ContentfulContentType.PARAGRAPH:
      return (
        <Paragraph
          paragraph={fields.paragraph}
          bodyFontSize={fields.bodyFontSize}
        />
      );
    case ContentfulContentType.TEXT:
      return <Text {...fields} />;
    default:
      console.log(`Section type not found: ${sectionType}`);
      return null;
  }
};

export default renderComponent;
