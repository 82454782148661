import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CategorizedDisplay as CategorizedDisplayProps } from './types/types';
import LinkedContent from '../LinkedContent';
import { renderContentSections } from '../renderCategorizedContent';
import { ContentfulContentType } from '../types/enums';

export const StyledCategorySection = styled.div`
  .row > div {
    @media (max-width: 760px) {
      &:nth-child(odd) {
        padding: 0 0.5rem 0 1rem;
      }

      &:nth-child(even) {
        padding: 0 1rem 0 0.5rem;
      }
    }
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0 0 2rem;
    padding: 0;
    border-bottom: 1px solid #e5e5e5;
    justify-content: space-evenly;
  }

  li {
    list-style: none;
  }

  .padding__bottom--2x {
    padding: 0 0 1.5rem;
  }
`;

const CategorizedDisplay = ({
  categorizedElements,
  categorizedLinks,
  displayAs,
  id,
  filterByText,
}: CategorizedDisplayProps) => {
  const [showId, setIdToShow] = useState(0);

  // change state on selection
  useEffect(() => {
    // eslint-disable-next-line max-len
    if (categorizedElements.length !== 0 && showId === null)
      setIdToShow(categorizedLinks?.fields[0]?.id);
  }, [showId]);

  const changeView = (index) => {
    setIdToShow(index);
  };

  const renderLinks = (link, index) => {
    const categorizedLinkStyle =
      link?.sys?.contentType?.sys?.id || link?.contentfulContentType;
    switch (categorizedLinkStyle) {
      case ContentfulContentType.LINKEDNAVIGATIONSTYLELINKS:
        return (
          <LinkedContent
            key={index}
            displayText={link.displayText}
            index={index}
            showId={showId}
            changedIdCallback={() => changeView(index)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <StyledCategorySection>
      <div className="categorized categorized__section categorized--justify padding__bottom--2x">
        {displayAs === 'dropdown' ? (
          <div className="row">
            <label
              id={`${filterByText}-${id}`}
              htmlFor={id}
            >
              {filterByText}
            </label>
            <select
              aria-labelledby={`${filterByText}-${id}`}
              id={id}
              onChange={(e) => setIdToShow(Number(e.target.value))}
            >
              {categorizedElements?.map((item, index) => (
                <option
                  selected={showId === item.index}
                  value={index}
                >
                  {item.displayText}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <ul className="row">
            {categorizedElements?.map((link, index) =>
              renderLinks(link, index)
            )}
          </ul>
        )}
      </div>
      <div className="justify-center">
        {categorizedElements
          ?.map((link, index) => {
            // eslint-disable-next-line no-param-reassign
            link.index = index;
            return link;
          })
          .filter((x) => {
            return x.index === showId;
          })
          .map((x, index) =>
            x && x.linkedContent && x.linkedContent.length !== 0 ? (
              x.linkedContent.map((content) => (
                <li key={index}>{renderContentSections(content)}</li>
              ))
            ) : (
              <div>Nothing to display.</div>
            )
          )}
      </div>
    </StyledCategorySection>
  );
};

export default CategorizedDisplay;
