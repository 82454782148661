import { setCookie } from '../../../utils/cookieUtils';
import { LOCALE_COOKIE_NAME_STRING } from './constants';
import { LocaleChangeUrlData } from '../types/types';

export const useI18 = ({ locale, DEFAULT_LOCALE }: LocaleChangeUrlData) => {
  const changePathnameLocale = (newLocale: string, slug: string): string => {
    if (newLocale === locale.toLowerCase()) return '';
    // eslint-disable-next-line no-param-reassign
    if (newLocale === DEFAULT_LOCALE) newLocale = '';
    if (slug === '') return `/${newLocale.toLowerCase()}`;
    if (newLocale === '') return `/${slug}`;
    return `/${newLocale.toLowerCase()}/${slug}`;
  };

  const createLocalizedPathname = (slug: string): string =>
    `/${slug}/${locale.toLowerCase()}`;

  return {
    changePathnameLocale,
    createLocalizedPathname,
  };
};

export default { useI18 };
