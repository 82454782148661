import { LOCALE_COOKIE_NAME_STRING } from '../components/LanguageSelectorContainer/hooks/constants';
import { getCookie, setCookie, unsetCookie } from './cookieUtils';

const readSezzleCookieLocale = (): string => {
  const sezzleLocale = getCookie(LOCALE_COOKIE_NAME_STRING);
  if (sezzleLocale !== 'undefined') return sezzleLocale;
  return null;
};

const getLocaleFromNavigator = (DEFAULT_LOCALE): string => {
  if (typeof navigator === 'undefined' || !navigator) {
    return DEFAULT_LOCALE;
  }
  return navigator.language || (navigator.languages && navigator.languages[0]);
};
export const cookieLocaleRegex = (locale: string): boolean =>
  /^[a-z]{2}-[a-z]{2}$/.test(locale);

const lowerCaseLocaleRegex = (locale: string): boolean =>
  /[a-z][a-z]-[a-z][a-z]/.test(locale);

const localeRegex = (locale: string): boolean =>
  /[a-z][a-z]-[A-Z][A-Z]/.test(locale);

export const formatLocale = (locale: string): string => {
  if (localeRegex(locale)) {
    return locale;
  }

  if (lowerCaseLocaleRegex(locale)) {
    const splitLocale = locale.split('-');
    return `${splitLocale[0]}-${splitLocale[1].toUpperCase()}`;
  }

  throw new Error(`Unrecognized format. Cannot reformat ${locale}`);
};

// eslint-disable-next-line max-len
const findPartialLocaleMatch = (
  partialLocale: string,
  SUPPORTED_LOCALES: string[]
): string =>
  SUPPORTED_LOCALES.filter(
    (locale) => locale.substring(0, 2) === partialLocale
  )[0];

// safari can set locale as "en" or "fr" instead of "en-US", "fr-CA". Add regex checks to match best locale edge cases.
const getDerivedLocale = (
  DEFAULT_LOCALE: string,
  SUPPORTED_LOCALES: string[],
  countryCode?: string
): string => {
  const locale = getLocaleFromNavigator(DEFAULT_LOCALE);
  const localePrefix = locale.substring(0, 2);

  switch (countryCode) {
    case 'US':
      return 'en-US';
    case 'CA':
      return localePrefix.toLowerCase() === 'fr' ? 'fr-CA' : 'en-CA';
  }

  if (localeRegex(locale) || lowerCaseLocaleRegex(locale)) {
    return formatLocale(locale);
  }

  if (locale.length === 2) {
    return findPartialLocaleMatch(locale, SUPPORTED_LOCALES);
  }
  return DEFAULT_LOCALE;
};

// We determine the user's locale and set it in a cookie
export const setUserLocale = (
  DEFAULT_LOCALE,
  SUPPORTED_LOCALES,
  countryCode?: string
): string => {
  const computedLocale =
    readSezzleCookieLocale() ||
    getDerivedLocale(DEFAULT_LOCALE, SUPPORTED_LOCALES, countryCode) ||
    DEFAULT_LOCALE;
  if (!SUPPORTED_LOCALES.includes(computedLocale)) {
    unsetCookie(LOCALE_COOKIE_NAME_STRING);
    setCookie(LOCALE_COOKIE_NAME_STRING, DEFAULT_LOCALE);
  } else {
    const today = new Date();
    const expiry = new Date(today.setDate(today.getDate() + 60)); // 2 - months
    setCookie(LOCALE_COOKIE_NAME_STRING, computedLocale, expiry);
  }
  return computedLocale;
};

export default { setUserLocale };
