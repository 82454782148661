import React from 'react';
import styled from 'styled-components';

interface OwnProps {
  fields: any;
}

export const StyledAnchor = styled.a`
  color: ${(props: any) => props.theme.linkColor};
  text-decoration: underline !important;
  font-family: ${(props: any) => props.theme.bodyFont};

  &:hover {
    color: ${(props: any) => props.theme.linkColorHover};
  }
`;

export const StyledButton = styled.a<Partial<OwnProps>>`
  background-color: ${(props: any) =>
    props.type === 'Primary Button'
      ? props.theme.primaryButton
      : props.theme.secondaryButton};
  color: ${(props: any) =>
    props.type === 'Primary Button'
      ? props.theme.primaryButtonText
      : props.theme.secondaryButtonTextColor};
  height: 35px;
  font-size: 1rem;
  letter-spacing: -0.25px;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  font-weight: 400;
  font-family: ${(props: any) => props.theme.buttonFont} !important;
  overflow: hidden;
  outline: none;
  display: inline-block;
  -webkit-transition: 0.01s;
  transition: 0.01s;
  padding: 0 30px;
  border-radius: 40px;
  border: ${(props: any) =>
    props.type === 'Primary Button'
      ? `1px solid ${props.theme.primaryButtonBorder}`
      : `1px solid ${props.theme.secondaryButtonBorder}`};
  text-decoration: none !important;

  &:hover {
    cursor: pointer;
    color: ${(props: any) =>
      props.type === 'Primary Button'
        ? props.theme.primaryButtonTextHover
        : props.theme.secondaryButtonTextColorHover};
    background-color: ${(props: any) =>
      props.type === 'Primary Button'
        ? props.theme.primaryButtonHover
        : props.theme.secondaryButtonHover};
    border: ${(props: any) =>
      props.type === 'Primary Button'
        ? `1px solid ${props.theme.primaryButtonBorderHover}`
        : `1px solid ${props.theme.secondaryButtonBorderHover}`};
    text-decoration: none !important;
  }

  @media (max-width: 768px) {
    width: fit-content;
    height: 36px;
    font-size: 1rem;
    line-height: 32px;
    padding: 0 16px;
  }
`;

const DownloadAnchor = (props: Partial<OwnProps>) => {
  const { fields } = props;

  const renderDownloadLink = (downloadFields) => {
    switch (downloadFields.style) {
      case 'Link':
        return (
          <StyledAnchor
            href={`https:${downloadFields?.media?.file?.url}`}
            data-component-type="link"
            className="trk-ga"
            target="_blank"
            download
          >
            {downloadFields?.displayText}
          </StyledAnchor>
        );
      case 'Primary Button':
      case 'Secondary Button':
        return (
          <StyledButton
            data-component-type="button-styled-link"
            href={`https:${downloadFields?.media?.file?.url}`}
            type={fields.style}
            className="trk-ga"
            target="_blank"
            rel="noreferrer"
            download
          >
            {downloadFields?.displayText}
          </StyledButton>
        );
      default:
        return (
          <StyledAnchor
            href={`https:${downloadFields?.media?.file?.url}`}
            data-component-type="link"
            className="trk-ga"
            target="_blank"
            download
          >
            {downloadFields?.displayText}
          </StyledAnchor>
        );
    }
  };
  return renderDownloadLink(fields);
};

export default DownloadAnchor;
