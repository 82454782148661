import React from 'react';
import styled from 'styled-components';
import { Card as CardProps } from './types/types';
import { renderCTAOptions } from '../renderCTAOptions';
import Image from '../Image';
import ImageLink from '../ImageLink';
import { ContentfulContentType } from '../types/enums';
import Paragraph from '../Paragraph';
import Text from '../Text';
import { setColor } from '../../utils/setColor';

const CardComponent = styled.div<Partial<CardProps>>`
  height: 100%;
  border: ${(props: any) =>
    props.borderColor !== null && props.borderColor !== undefined
      ? `2px solid ${setColor(props.borderColor, props)}`
      : '2px solid #e5e5e5'};
  box-sizing: border-box;
  padding: 1rem;
  margin: 1rem 0 0;
  border-radius: 5px;
  background-color: ${(props: any) =>
    props.backgroundColor !== 'none'
      ? setColor(props.backgroundColor, props)
      : 'inherit'};

  @media (max-width: 576px) {
    margin: 1rem 0;
  }

  .card--center {
    text-align: center;
  }

  img {
    width: 100%;
  }

  a {
    margin: auto 0;
  }

  .card-content {
    &__cta {
      display: flex;
      justify-content: space-between;
      padding: 0 0 1rem;
    }

    &__block {
      display: block;
      padding: 0;

      a {
        margin: 1rem 0 auto;
      }
    }

    span {
      padding: 0;
      p {
        margin: auto 0;
        color: #382757;
        padding: 0;
      }
    }

    p {
      margin: auto 0;
      color: #382757;
      padding: 0;
    }

    @media (max-width: 768px) {
      a.button-primary {
        width: fit-content;
        height: 31px;
        font-size: 0.75em;
        line-height: 28px;
        padding: 0 20px;
      }
    }
  }

  .card--left {
    text-align: left;
  }

  .card--center {
    text-align: center;
  }

  .card--justify {
    text-align: justify;
  }

  .card--right {
    text-align: right;
  }

  h3 {
    margin-top: 1.5rem;
  }
`;

const Card = ({
  top,
  title,
  subtitle,
  link,
  borderColor,
  alignment,
  template,
  longSubTitle,
  backgroundColor,
}: CardProps) => {
  const renderSection = () => {
    const topSectionType =
      top?.sys?.contentType?.sys?.id || top?.contentfulContentType;
    switch (topSectionType) {
      case ContentfulContentType.IMAGELINK:
        return (
          <ImageLink
            node_locale={top.node_locale}
            shouldTextShow={top.shouldTextShow}
            url={top.imageUrl || top.url}
            image={top.image}
            textAlignment={top.textAlignment}
            assistiveText={top.assistiveText}
          />
        );
      case ContentfulContentType.IMAGE:
        return <Image fields={top} />;
      default:
        return null;
    }
  };

  const renderLongSubTitleSection = () => {
    const longSubTitleSectionType =
      longSubTitle?.sys?.contentType?.sys?.id ||
      longSubTitle?.contentfulContentType;
    switch (longSubTitleSectionType) {
      case ContentfulContentType.PARAGRAPH:
        if (template == 'Card Template 2') {
          return (
            <span className="body__copy--small">
              <Paragraph
                bodyFontSize={null}
                paragraph={longSubTitle.paragraph}
              />
            </span>
          );
        } else {
          return (
            <span className="body__copy--small col-xs-8">
              <Paragraph
                bodyFontSize={null}
                paragraph={longSubTitle.paragraph}
              />
            </span>
          );
        }
      case ContentfulContentType.TEXT:
        <Text {...longSubTitle} />;
      default:
        return null;
    }
  };

  return (
    <CardComponent
      data-content-type="card"
      borderColor={borderColor}
      data-testid="card"
      backgroundColor={backgroundColor}
    >
      <div className="card card__top card--center">
        {top && renderSection()}
      </div>
      {/* displays card template 1 by default */}
      {template !== 'Card Template 2' && (
        <div
          className={`card card__content ${alignment ? `card--${alignment}` : 'card--left'}`}
        >
          {title && <h3>{title}</h3>}

          <div className="card-content card-content__cta col-xs-12">
            {subtitle && !longSubTitle && (
              <p className="body__copy--small col-xs-8">{subtitle}</p>
            )}
            {longSubTitle && renderLongSubTitleSection()}
            {link &&
              renderCTAOptions(
                link?.sys?.contentType?.sys?.id || link?.contentfulContentType,
                link
              )}
          </div>
        </div>
      )}
      {template === 'Card Template 2' && (
        <div
          className={`card card__content ${alignment ? `card--${alignment}` : 'card--center'}`}
        >
          {title && <h3>{title}</h3>}
          <div className="card-content card-content__block col-xs-12">
            {subtitle && !longSubTitle && (
              <p className="body__copy--small">{subtitle}</p>
            )}
            {longSubTitle && renderLongSubTitleSection()}
            {link &&
              renderCTAOptions(
                link?.sys?.contentType?.sys?.id || link?.contentfulContentType,
                link
              )}
          </div>
        </div>
      )}
    </CardComponent>
  );
};

export default Card;
