import React, { Suspense } from 'react';
import EmbeddedScript from '../../../../EmbeddedScript';
import { HeroTemplate2 as HeroTemplate2Props } from './types';
import { Grid } from '@sezzle/sezzle-mui';
import HeroText from '../../HeroText';
import { StyledHeroTemplateWrapper } from '../styles';
import ClientOnly from '../../../../ClientOnly';

const HeroTemplate2 = ({
  h1Header,
  h1HeaderFont,
  mobileCtas,
  buttonPlacementBelowSubcopy,
  bodyCopy1,
  bodyCopy2,
  bodyCopy3,
  embeddedScript,
  bodyCopy1Font,
  bodyCopy2Font,
  bodyCopy3Font,
  extraSmallLeftSide,
  smallLeftSide,
  mediumLeftSide,
  largeLeftSide,
}: HeroTemplate2Props) => {
  h1HeaderFont = h1HeaderFont || 'CTALarge';
  bodyCopy1Font = bodyCopy1Font || 'Title3';
  bodyCopy2Font = bodyCopy2Font || 'Title3';
  bodyCopy3Font = bodyCopy3Font || 'Title3';

  const xs = parseInt(extraSmallLeftSide) || 12;
  const sm = parseInt(smallLeftSide) || 12;
  const md = parseInt(mediumLeftSide) || 5;
  const lg = parseInt(largeLeftSide) || 4;

  return (
    <StyledHeroTemplateWrapper
      container
      spacing={3}
      data-component-type="hero-template-2"
    >
      <Grid
        item
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
      >
        <HeroText
          h1Header={h1Header}
          h1HeaderFont={h1HeaderFont}
          mobileCtas={mobileCtas}
          buttonPlacementBelowSubcopy={buttonPlacementBelowSubcopy}
          bodyCopy1={bodyCopy1}
          bodyCopy1Font={bodyCopy1Font}
          bodyCopy2={bodyCopy2}
          bodyCopy2Font={bodyCopy2Font}
          bodyCopy3={bodyCopy3}
          bodyCopy3Font={bodyCopy3Font}
        />
        <ClientOnly>
          <Suspense fallback={<div />}>
            {embeddedScript &&
              embeddedScript.map((item, index) => (
                <Suspense fallback={<div />}>
                  <EmbeddedScript
                    key={index}
                    scriptSource={item.scriptSource}
                    scriptInnerHtml={item.scriptInnerHtml}
                    scriptAppendToId={item.scriptAppendToId}
                    embeddedHtml={item.embeddedHtml}
                  />
                </Suspense>
              ))}
          </Suspense>
        </ClientOnly>
      </Grid>
    </StyledHeroTemplateWrapper>
  );
};

export default HeroTemplate2;
