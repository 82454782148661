import * as FullStory from '@fullstory/browser';

/* eslint-disable */
const initFullStory = () => {
  FullStory.init({
    orgId: process.env.GATSBY_FULLSTORY_ORG_ID,
    recordOnlyThisIFrame: true,
    devMode:
      process.env.GATSBY_TARGET_ENV === 'development' ||
      process.env.GATSBY_TARGET_ENV === 'staging',
  });
};

export default initFullStory;
