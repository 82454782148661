import React from 'react';
import styled from 'styled-components';
import { Stats as StatsProps } from './types/types';

export const statsColor = (statColor, props) => {
  const colorOptions = props.theme.headerColors;
  let returnColor;
  Object.keys(colorOptions).forEach((color) => {
    if (statColor === color) {
      returnColor = props.theme.headerColors[color];
    }
  });
  return returnColor;
};

const StyledStatsType = styled.div<Partial<StatsProps>>`
  text-align: ${(props) => props.alignment || 'center'};
  h3 {
    color: ${(props) => statsColor(props.statColor, props) || '#382757'};
    font-weight: 500;
    font-size: 3rem;
    line-height: 58px;
    letter-spacing: normal;
    margin: 10px 0;

    .heading__3--body-copy-large {
      font-size: 1.125rem;
      font-family: ${(props: any) => `${props.theme.bodyFont}`};
      display: block;
      color: #382757;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 0;
      letter-spacing: normal;
    }
  }

  p.body__copy--stat {
    font-family: ${(props: any) => `${props.theme.bodyFont}`};
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 21px;
    color: ${(props) => statsColor(props.statColor, props) || '#382757'};
    margin-top: 0;
    margin-bottom: 0;
  }

  p.body__copy--xsmall {
    font-size: 0.625rem;
    line-height: 13px;
    font-style: normal;
    font-weight: bold;
    font-family: Nunito Sans;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Stats = ({
  statColor,
  alignment,
  superStat,
  supportingCopy,
  supportingStat,
  disclaimerCopy,
}: StatsProps) => {
  return (
    <StyledStatsType
      statColor={statColor}
      alignment={alignment}
    >
      {superStat && (
        <h3>
          {superStat}
          {supportingCopy && (
            <span className="heading__3--body-copy-large">
              {supportingCopy}
            </span>
          )}
        </h3>
      )}
      {supportingStat && (
        <p className="body__copy--small body__copy--stat">{supportingStat}</p>
      )}
      {disclaimerCopy && <p className="body__copy--xsmall">{disclaimerCopy}</p>}
    </StyledStatsType>
  );
};

export default Stats;
