import React from 'react';
import { AccordionContainer as AccordionContainerProps } from './types/types';
import AccordionLineItem from '../AccordionLineItem';
import styled from 'styled-components';

export const StyledContainer = styled.div<{
  $maxWidth?: string;
  $itemPadding?: string;
}>`
  max-width: ${(props) => props.$maxWidth || '100%'};
  margin: auto;
  .line {
    padding: ${(props) => `${props.$itemPadding || 0} 0`};
    border-bottom: 1px solid lightgrey;
  }
`;

const AccordionContainer = ({
  headerTag,
  headerFontSize,
  headerTextAlign,
  panelTag,
  panelFontSize,
  panelTextAlign,
  chevronSize,
  maxAccordionWidth,
  itemPadding,
  lines,
}: AccordionContainerProps) => {
  return (
    <StyledContainer
      $maxWidth={maxAccordionWidth}
      $itemPadding={itemPadding}
    >
      {lines.map((line) => {
        return (
          <div className="line">
            <AccordionLineItem
              headerTag={headerTag}
              headerFontSize={headerFontSize}
              headerTextAlign={headerTextAlign}
              panelTag={panelTag}
              panelFontSize={panelFontSize}
              panelTextAlign={panelTextAlign}
              chevronSize={chevronSize}
              {...line}
            />
          </div>
        );
      })}
    </StyledContainer>
  );
};

export default AccordionContainer;
