import React from 'react';

const evaluateCondition = (shouldDisplay) => {
  if (
    shouldDisplay === null ||
    shouldDisplay === undefined ||
    shouldDisplay === 'Always'
  ) {
    return true;
  }
  if (shouldDisplay === 'Never') {
    return false;
  }
  // If component should be displayed when a token is not present
  if (shouldDisplay.includes('is not')) {
    return shouldDisplay.split(' ')[1] === '';
  }
  // If component should be displayed when a token is not present
  return shouldDisplay.split(' ')[1] !== '';
};

export const ConditionalDisplayComponent = (Component) => {
  return (props) => {
    const { shouldDisplay, ...otherProps } = props;
    if (!evaluateCondition(shouldDisplay)) {
      return null;
    }
    return <Component {...otherProps} />;
  };
};
