import React, { useState, useCallback } from 'react';
import { Link, List, Box, ClickAwayListener } from '@sezzle/sezzle-mui';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ContentfulContentType } from '../../../../../types/enums';
import { CategoryMenuPopoverProps } from './types';
import ImageLinkWithTextDropdownMenu from '../ImageLinkWithTextDropdownMenu';
import HeadlineDropdownMenu from '../HeadlineDropdownMenu';
import SetOfDropdownMenu from '../SetOfDropdownMenu';
import CategoryLinks from '../CategoryLinks';
import CTALink from '../../../../../CTA/LinkButton';
import {
  StyledCategoryMenuDropDownButton,
  StyledCategoryMenuPopover,
  StyledCategoryListContainer,
  StyledSubCategoryListContainer,
  StyledCategoryButton,
} from './styles';
import LinkButton from '../../../../../CTA/LinkButton';

export const CategoryMenuPopover = ({
  index,
  linkUrl,
  displayText,
  linkedContent,
}: Partial<CategoryMenuPopoverProps>) => {
  const [showDropDown, setShowDropDown] = useState<HTMLElement | null>(null);

  const open = Boolean(showDropDown);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setShowDropDown(event.currentTarget);
    },
    [setShowDropDown]
  );

  const handlePopoverClose = useCallback(() => {
    setShowDropDown(null);
  }, [setShowDropDown]);

  const renderContentSections = (content) => {
    const categorizedContent =
      content?.sys?.contentType?.sys?.id || content?.contentfulContentType;

    switch (categorizedContent) {
      case ContentfulContentType.COLLAPSIBLELIST:
        return (
          <CategoryLinks
            listHeader={content.listHeader}
            list={content.list}
          />
        );
      case ContentfulContentType.CTA:
        return (
          <CTALink
            url={content.url}
            function={content.function}
            displayText={content.displayText}
          />
        );
      case ContentfulContentType.HEADLINE:
        return (
          <HeadlineDropdownMenu
            contentfulid={content.contentfulid}
            header={content.header}
            alignment={content.alignment}
            subcopy={content.subcopy}
            subCopySmall={content.subCopySmall}
            link={content.link}
          />
        );
      case ContentfulContentType.IMAGELINKWITHTEXT:
        return (
          <ImageLinkWithTextDropdownMenu
            url={content.imageUrl || content.url}
            image={content.image}
            displayText={content.displayText}
            textAlignment={content.textAlignment}
          />
        );
      case ContentfulContentType.SETOF:
        return <SetOfDropdownMenu fields={content} />;
      default:
        return <div data-content-type="not-found">Unknown Content Type</div>;
    }
  };

  return (
    <ClickAwayListener onClickAway={handlePopoverClose}>
      <Box>
        {linkUrl ? (
          <Link href={linkUrl}>{displayText}</Link>
        ) : (
          <StyledCategoryMenuDropDownButton onMouseEnter={handlePopoverOpen}>
            <LinkButton
              displayText={displayText}
              onClick={handlePopoverOpen}
              aria-expanded={open}
              ariaControls={`${displayText}-${index}`}
            />
            {showDropDown ? (
              <ExpandLess fontSize="small" />
            ) : (
              <ExpandMore fontSize="small" />
            )}
          </StyledCategoryMenuDropDownButton>
        )}

        <StyledCategoryMenuPopover
          anchorEl={showDropDown}
          id="mouse-over-popover"
          open={open}
          onClose={handlePopoverClose}
          elevation={0}
        >
          <StyledCategoryListContainer id={`${displayText}-${index}`}>
            <StyledSubCategoryListContainer>
              {linkedContent?.map((content, index) => (
                <List key={index}>
                  <StyledCategoryButton>
                    {renderContentSections(content)}
                  </StyledCategoryButton>
                </List>
              ))}
            </StyledSubCategoryListContainer>
          </StyledCategoryListContainer>
        </StyledCategoryMenuPopover>
      </Box>
    </ClickAwayListener>
  );
};
