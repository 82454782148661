/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import CTA from '../CTA';
import Paragraph from '../Paragraph';
import Text from '../Text';
import { ContentfulContentType } from '../types/enums';

interface OwnProps {
  fields: any;
}

const FooterRow = styled.div`
  justify-content: center;

  span {
    display: inline-flex;
    padding: 3rem 1rem 0;
  }
`;

const BottomRow = (props: OwnProps) => {
  const { fields } = props;
  const renderRowOptions = (sectionType, rowOptionFields) => {
    switch (sectionType) {
      case ContentfulContentType.CTA:
        return <CTA fields={rowOptionFields} />;
      case ContentfulContentType.PARAGRAPH:
        return (
          <Paragraph
            paragraph={rowOptionFields.paragraph}
            bodyFontSize={rowOptionFields.bodyFontSize}
          />
        );
      case ContentfulContentType.TEXT:
        return <Text {...rowOptionFields} />;
      default:
        console.log(`Section type not found: ${sectionType}`);
        return null;
    }
  };

  return (
    <FooterRow className="row">
      {fields?.map((item, index) => (
        <span key={index}>
          {renderRowOptions(
            item?.sys?.contentType?.sys?.id || item?.contentfulContentType,
            item
          )}
        </span>
      ))}
    </FooterRow>
  );
};

export default BottomRow;
