import React from 'react';
import styled from 'styled-components';
import CTA from '../CTA';
import Image from '../Image';
import ImageLink from '../ImageLink';
import LanguageSelectorContainer from '../LanguageSelectorContainer';
import Paragraph from '../Paragraph';
import Text from '../Text';
import renderImageComponent from '../renderImageComponent';
import SetOf from '../SetOf';
import { ContentfulContentType } from '../types/enums';

interface OwnProps {
  fields: any;
  logo: any;
  pageContext: Record<string, any>;
}

const StyledColumn = styled.div`
  justify-content: left;
  padding-right: 4rem;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  margin-top: 1rem;

  @media (max-width: 576px) {
    justify-content: left;
    padding-top: 2rem;
    order: 2;
    flex-basis: 100%;
    padding-right: 0;
    min-width: 100%;
    max-width: 100%;
  }

  .logo__container {
    max-height: 34px;
    justify-content: flex-start;
    margin-bottom: 2rem;

    img {
      max-height: 34px;
      width: auto;
    }
  }

  .gatsby-image-wrapper {
    overflow: visible;

    @media (max-width: 768px) {
      height: 40px;
    }
  }

  img {
    object-fit: contain !important;
    height: 50px;
    width: auto;
  }
`;

const InfoColumn = ({ fields, logo, pageContext }: OwnProps) => {
  const renderInfoColumn = (sectionType, section) => {
    switch (sectionType) {
      case ContentfulContentType.CTA:
        return <CTA fields={section} />;
      case ContentfulContentType.IMAGE:
        return <Image fields={section} />;
      case ContentfulContentType.IMAGELINK:
        return (
          <ImageLink
            node_locale={section.node_locale}
            shouldTextShow={section.shouldTextShow}
            url={section.imageUrl || section.url}
            image={section.image}
            textAlignment={section.textAlignment}
            assistiveText={section.assistiveText}
          />
        );
      case ContentfulContentType.PARAGRAPH:
        return (
          <Paragraph
            paragraph={section.paragraph}
            bodyFontSize={section.bodyFontSize}
          />
        );
      case ContentfulContentType.TEXT:
        return <Text {...section} />;
      case ContentfulContentType.SETOF:
        return <SetOf fields={section} />;
      case ContentfulContentType.SPECIFIC:
        switch (section.specificContentType) {
          // case ('Store Search'):
          //   return <StoreSearch />
          case 'Language Selector':
            return (
              <LanguageSelectorContainer
                languageSelectorTranslation={
                  section.languageSelectorTranslation
                }
                pageContext={pageContext}
              />
            );
          default:
            console.log(`Section type not found: ${sectionType}`);
            return null;
        }

      default:
        console.log(`Section type not found: ${sectionType}`);
        return null;
    }
  };

  return (
    <StyledColumn>
      {logo && (
        <div className="logo__container">
          {renderImageComponent(
            logo?.sys?.contentType?.sys?.id || logo?.contentfulContentType,
            logo
          )}
        </div>
      )}
      {fields?.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          {renderInfoColumn(
            item?.sys?.contentType?.sys?.id || item?.contentfulContentType,
            item
          )}
        </div>
      ))}
    </StyledColumn>
  );
};

export default InfoColumn;
