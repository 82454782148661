import React, { useMemo } from 'react';
import { Button } from '@sezzle/sezzle-mui';
import styled from 'styled-components';
import { SezzleButtonProps } from './types';
import { setPrefix } from '../../../utils/helpers';
import { defaultLocale } from '../../types/constants';
import { CTASelections } from '../../types/enums';
import sezzleTheme from '../../../../../packages/website/src/templates/Page/themes';

const isBrowser = typeof window !== 'undefined';

const StyledButton = styled(Button)`
  font-family: ${sezzleTheme.bodyFont};
`;

const SezzleButton = ({
  node_locale,
  url,
  style,
  displayText,
  buttonSize = 'large',
  className,
  onClick,
  fullWidth,
  ...props
}: SezzleButtonProps) => {
  const localePrefix =
    node_locale && node_locale === defaultLocale
      ? ''
      : `/${node_locale?.toLowerCase()}`;

  const [buttonURL, setButtonURL] = React.useState('');

  React.useEffect(
    () =>
      setButtonURL(
        !url?.startsWith('/')
          ? setPrefix(props.function, url)
          : `${localePrefix}${url}` || `${localePrefix}${url}`
      ),
    [url, props.function, localePrefix]
  );

  React.useEffect(() => {
    let observer: MutationObserver;
    const divElement = document.getElementById('one_link_url');
    if (divElement) {
      // set the url
      const val = (divElement as HTMLInputElement).value;
      if (isBrowser && url === '{{af_smart_script_link}}') {
        setButtonURL(val);
      }
    } else {
      observer = new MutationObserver(() => {
        const divElement = document.getElementById('one_link_url');
        if (divElement) {
          observer.disconnect();
          observer = null;
          // set the url
          const val = (divElement as HTMLInputElement).value;
          if (isBrowser && url === '{{af_smart_script_link}}') {
            setButtonURL(val);
          }
        }
      });
      observer.observe(document, { subtree: true, childList: true });
    }

    return () => {
      if (observer) {
        observer.disconnect();
        observer = null;
      }
    };
  }, []);

  const customVariant = useMemo(() => {
    switch (style) {
      case CTASelections.PrimaryButton:
        return 'Primary';
      case CTASelections.SecondaryButton:
        return 'Secondary';
      default:
        return 'Primary';
    }
  }, []);

  return (
    <StyledButton
      data-testid="buttonAnchor"
      data-component-type="button-styled-link"
      href={buttonURL}
      variant={customVariant || 'Primary'}
      size={buttonSize}
      fullWidth={fullWidth}
      onClick={onClick}
      className={className}
    >
      {displayText}
    </StyledButton>
  );
};

export default SezzleButton;
