import React from 'react';
import { CategoryAccordion } from './NavigationBarAndMenu/components/HamburgerMenu/components/CategoryAccordion';
import CTA from './CTA';
import Headline from './Headline';
import { ContentfulContentType } from './types/enums';

export const renderCTAOptions = (sectionType, fields) => {
  switch (sectionType) {
    case ContentfulContentType.COLLAPSIBLELIST:
      return (
        <CategoryAccordion
          listHeader={fields.listHeader}
          headerLevel={fields.headerLevel}
          list={fields.list}
        />
      );
    case ContentfulContentType.CTA:
      return <CTA fields={fields} />;
    case ContentfulContentType.HEADLINE:
      return (
        <Headline
          contentfulid={fields.contentfulid}
          header={fields.header}
          alignment={fields.alignment}
          subcopy={fields.subcopy}
          subCopySmall={fields.subCopySmall}
          link={fields.link}
        />
      );
    default:
      console.log(`Section type not found: ${sectionType}`);
      return null;
  }
};

export default renderCTAOptions;
