import React, { useState } from 'react';
import { Menu, Close } from '@mui/icons-material';
import {
  Box,
  List,
  ListItem,
  IconButton,
  Drawer,
  ListItemText,
  Divider,
} from '@sezzle/sezzle-mui';
import { HamburgerMenu as HamburgerMenuProps } from './types';
import renderFeaturedMobileSpot from '../../../renderFeaturedMobileSpot';
import {
  StyledHamburgerMenuDrawer,
  StyledHamburgerMenuContent,
  StyledButtonList,
  StyledCloseIcon,
} from './styles';
import { CategoryAccordion } from './components/CategoryAccordion';
import { ContentfulContentType } from '../../../types/enums';
import CTA from '../../../CTA';
import DownloadCTA from '../../../CTA/DownloadCTA';

const HamburgerMenu = ({
  featuredLinksMobileMenu,
  mobileNavigationLinks,
  featuredMobileSpot,
  ctaButtons,
  screenConfig,
}: HamburgerMenuProps) => {
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const renderMobileCTAOptions = (sectionType, fields) => {
    switch (sectionType) {
      case ContentfulContentType.COLLAPSIBLELIST:
        return (
          <CategoryAccordion
            listHeader={fields.listHeader}
            headerLevel={fields.headerLevel}
            list={fields.list}
          />
        );
      case ContentfulContentType.CTA:
        return <CTA fields={fields} />;
      case ContentfulContentType.DOWNLOADCTA:
        return <DownloadCTA fields={fields} />;
      default:
        console.log(`Section type not found: ${sectionType}`);
        return null;
    }
  };

  return (
    <Box className="mobile-only">
      <IconButton
        size="large"
        edge="start"
        color="primary"
        aria-label="open drawer"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={() => setShowHamburgerMenu(!showHamburgerMenu)}
        data-testid="hamburgerIcon"
      >
        <Menu fontSize="large" />
      </IconButton>
      <Drawer
        anchor="left"
        open={showHamburgerMenu}
        onClose={() => setShowHamburgerMenu(false)}
        PaperProps={{
          sx: {
            width: screenConfig.isMobile
              ? '70vw'
              : screenConfig.isTablet
                ? '50vw'
                : undefined,
          },
        }}
      >
        <StyledHamburgerMenuDrawer>
          <StyledCloseIcon
            onClick={() => setShowHamburgerMenu(false)}
            aria-label="close"
            color="primary"
          >
            <Close />
          </StyledCloseIcon>
          <StyledHamburgerMenuContent>
            {ctaButtons?.length !== 0 && (
              <>
                <StyledButtonList>
                  {ctaButtons?.map((item, index) => (
                    <ListItem
                      key={index}
                      onClick={() => setShowHamburgerMenu(!showHamburgerMenu)}
                    >
                      <ListItemText
                        primary={renderMobileCTAOptions(
                          item?.sys?.contentType?.sys?.id ||
                            item?.contentfulContentType,
                          item
                        )}
                      />
                    </ListItem>
                  ))}
                </StyledButtonList>
                <Divider />
              </>
            )}
            {mobileNavigationLinks?.length !== 0 && (
              <>
                <List>
                  {mobileNavigationLinks?.map((item, index) => (
                    <ListItem
                      key={index}
                      onClick={() => setShowHamburgerMenu(!showHamburgerMenu)}
                    >
                      <ListItemText
                        primary={renderMobileCTAOptions(
                          item?.sys?.contentType?.sys?.id ||
                            item?.contentfulContentType,
                          item
                        )}
                      />
                    </ListItem>
                  ))}
                </List>
                <Divider />
              </>
            )}
            {featuredLinksMobileMenu?.length !== 0 && (
              <List>
                {featuredLinksMobileMenu?.map((item, index) => (
                  <ListItem key={index}>
                    {renderMobileCTAOptions(
                      item?.sys?.contentType?.sys?.id ||
                        item?.contentfulContentType,
                      item
                    )}
                  </ListItem>
                ))}
              </List>
            )}
            {featuredMobileSpot && (
              <Box className="block__featured">
                {renderFeaturedMobileSpot(
                  featuredMobileSpot,
                  featuredMobileSpot?.sys?.contentType?.sys?.id ||
                    featuredMobileSpot?.contentfulContentType
                )}
              </Box>
            )}
          </StyledHamburgerMenuContent>
        </StyledHamburgerMenuDrawer>
      </Drawer>
    </Box>
  );
};

export default HamburgerMenu;
