/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import CollapsibleList from '../CollapsibleList';
import FooterLinks from './FooterLinks';

interface OwnProps {
  listHeader: string;
  list: Array<any>;
  headerLevel: number;
}

const Column = styled.div`
  justify-content: left;
  display: flex;
  flex-wrap: wrap;
  min-width: 250px;

  @media (max-width: 576px) {
    justify-content: left;
    padding: 0;
    min-width: 100%;
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    .footer__collapsed {
      display: block;
    }
    .footer__extended {
      display: none;
    }
  }

  @media (min-width: 769px) {
    .footer__collapsed {
      display: none;
    }
    .footer__extended {
      display: block;
    }
  }
`;

const Columns = (props: OwnProps) => {
  const { listHeader, headerLevel, list } = props;

  return (
    <Column>
      <span className="footer__collapsed">
        <CollapsibleList
          listHeader={listHeader}
          headerLevel={headerLevel}
          list={list}
        />
      </span>
      <span className="footer__extended">
        <FooterLinks
          listHeader={listHeader}
          list={list}
        />
      </span>
    </Column>
  );
};

export default Columns;
