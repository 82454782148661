import React from 'react';
import styled from 'styled-components';
import LinkButton from '../CTA/LinkButton';

interface OwnProps {
  fields: any;
}

const StyledNavBar = styled.nav`
  background-color: ${(props: any) => props.theme.primary};
  border-radius: 5px;
  padding: 1rem 0 !important;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: inline-flex;
    padding: 0;
  }

  li {
    flex: 0 0 auto;
  }

  a {
    color: ${(props: any) => props.theme.secondaryLinkColor};
    padding: 1rem;
    text-decoration: none;
  }
`;

const SubNavBar = (props: OwnProps) => {
  const { fields } = props;
  return (
    <StyledNavBar className="container row">
      <ul>
        {fields.navigationLinks?.length !== 0 &&
          fields.navigationLinks?.map((item, index) => (
            <li key={index}>
              <LinkButton
                node_locale={item.node_locale}
                url={item.url}
                displayText={item.displayText}
              />{' '}
            </li>
          ))}
      </ul>
    </StyledNavBar>
  );
};

export default SubNavBar;
