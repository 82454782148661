import React, { Suspense, useState } from 'react';
import { Box, List, ListItem, IconButton, Drawer } from '@sezzle/sezzle-mui';
import { Search, Close } from '@mui/icons-material';
import renderMobileShopOptions from '../../../renderMobileShopOptions';
import { FieldItem, SearchMenuProps } from './types';
import {
  StyledSearchMenuDrawer,
  StyledSearchMenuCloseIconButton,
  StyledSearchSuggestions,
} from './styles';

export const SearchMenu = ({
  fields,
  searchSuggestions,
  screenConfig,
}: SearchMenuProps) => {
  const [showSearchpMenu, setShowSearchMenu] = useState(false);

  return (
    <Box className="mobile-only">
      <IconButton
        size="large"
        aria-label="Shop Sezzle Stores"
        edge="end"
        color="primary"
        onClick={() => setShowSearchMenu(!showSearchpMenu)}
      >
        <Search fontSize="large" />
      </IconButton>
      <Drawer
        anchor="right"
        open={showSearchpMenu}
        onClose={() => setShowSearchMenu(false)}
        PaperProps={{
          sx: {
            width: screenConfig.isTablet ? { width: '50vw' } : undefined,
          },
        }}
      >
        <StyledSearchMenuDrawer>
          <StyledSearchMenuCloseIconButton
            onClick={() => setShowSearchMenu(false)}
            aria-label="close"
            color="primary"
          >
            <Close />
          </StyledSearchMenuCloseIconButton>
          <Box>
            <StyledSearchSuggestions>
              <Suspense fallback={<div />}>{searchSuggestions}</Suspense>
            </StyledSearchSuggestions>
            <List>
              {fields?.map((item: FieldItem, index: number) => (
                <ListItem key={index}>
                  {renderMobileShopOptions(
                    item.sys.contentType.sys.id || item?.contentfulContentType,
                    item
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
        </StyledSearchMenuDrawer>
      </Drawer>
    </Box>
  );
};
