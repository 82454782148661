import { styled } from '@mui/material';
import { Popover, List, Box } from '@sezzle/sezzle-mui';

export const StyledCategoryMenuDropDownButton = styled(Box)((props) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: props.theme.spacing(0.5),
}));

export const StyledCategoryMenuPopover = styled(Popover)((props) => ({
  maxHeight: '70vw',
  marginTop: props.theme.spacing(8.25),
  padding: props.theme.spacing(2, 'auto'),
  '& .MuiPaper-root': {
    outline: 'none !important',
    borderRadius: 0,
  },
}));

export const StyledCategoryListContainer = styled(List)(() => ({
  width: '100vw',
}));

export const StyledSubCategoryListContainer = styled(Box)((props) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  height: 'auto',
  maxHeight: props.theme.spacing(60),
  padding: props.theme.spacing(4, 2, 3, 2),
}));

export const StyledCategoryButton = styled(Box)((props) => ({
  marginBottom: props.theme.spacing(1),
}));
