import { styled } from '@mui/material';
import { sezzleTheme } from '../../../../packages/website/src/templates/Page/themes';
import { Box, Grid, TextField, Typography } from '@sezzle/sezzle-mui';
import SezzleButton from '../CTA/SezzleButton';

export const inputTextStyle = {
  fontSize: '14px',
};

export const StyledRevenueContainer = styled(Box)((props) => ({
  padding: props.theme.spacing(3),
  border: `1px solid ${sezzleTheme.colors.border.main}`,
  borderRadius: '1rem 1rem 6rem 1rem',
  gap: props.theme.spacing(1),
}));

export const StyledHeaderTextContainer = styled(Box)((props) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: props.theme.spacing(1),
  marginBottom: props.theme.spacing(2),
}));

export const StyledInputContainer = styled(Box)((props) => ({
  marginBottom: props.theme.spacing(1),
}));

export const StyledTextField = styled(TextField)((props) => ({
  backgroundColor: sezzleTheme.backgroundColor,
  marginBottom: props.theme.spacing(1),
  // height: '4.5rem',
  '& input:focus': {
    borderColor: 'transparent',
  },
}));

export const StyledCalculationContainer = styled(Grid)((props) => ({
  marginTop: props.theme.spacing(1),
  marginBottom: props.theme.spacing(2),
}));

export const StyledCalculateButton = styled(SezzleButton)((props) => ({
  marginTop: props.theme.spacing(2),
  marginBottom: props.theme.spacing(1),
}));

export const StyledResultContainer = styled(Grid)((props) => ({
  border: `2px solid ${sezzleTheme.secondary}`,
  borderRadius: '1rem 1rem 3rem 1rem',
  textAlign: 'center',
  padding: props.theme.spacing(2),
}));

export const StyledResultHeader = styled(Typography)((props) => ({
  marginTop: props.theme.spacing(2),
  marginBottom: props.theme.spacing(2),
  alignSelf: 'center',
  color: sezzleTheme.secondary,
}));

export const StyledResultSubHeader = styled(Typography)((props) => ({
  marginBottom: props.theme.spacing(4),
}));

export const StyledBodyDisclaimer = styled(Typography)((props) => ({
  marginTop: props.theme.spacing(2),
  marginBottom: props.theme.spacing(2),
}));
