export const setColor = (color, props) => {
  const colorOptions = props.theme.backgroundColors;
  let returnColor;
  Object.keys(colorOptions).forEach((colorOption) => {
    if (color === colorOption) {
      returnColor = props.theme.backgroundColors[color];
    }
  });
  return returnColor;
};
