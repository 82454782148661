/* eslint-disable import/no-duplicates */
/* eslint-disable max-len */
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useI18 } from './hooks/i18n';
import { cookieLocaleRegex, setUserLocale } from '../../utils/localeUtils';
import { getCookie, unsetCookie } from '../../utils/cookieUtils';
import {
  getCountryNameFromCountryCode,
  getLanguageFromLocale,
} from '../../utils/helpers';
import { LanguageSelector as LanguageSelectorProps } from './types/types';
import { LOCALE_COOKIE_NAME_STRING } from './hooks/constants';
import { getGeoIpDetails } from '../../../../packages/website/src/api/api';

export const LangSelector = styled.div`
  select#language-selector {
    align-items: center;
    max-width: 100px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }

  margin-top: 0.5rem;
`;

const LanguageSelectorComponent = ({
  pageContext,
  languageSelectorTranslation,
}: LanguageSelectorProps) => {
  const { DEFAULT_LOCALE, locale, SUPPORTED_LOCALES } = pageContext;
  const { changePathnameLocale } = useI18({
    locale,
    DEFAULT_LOCALE,
    SUPPORTED_LOCALES,
  });

  useEffect(() => {
    async function setLagnuage() {
      let ipDetails = undefined;
      try {
        ipDetails = await getGeoIpDetails();
      } catch (err) {
        console.error('Unable to fetch geoip details', err);
      }

      let currentCookie = getCookie(LOCALE_COOKIE_NAME_STRING);
      if (
        process.env.GATSBY_TARGET_ENV === '1' &&
        window.location.pathname.split('/')[1]?.toLowerCase() === 'es-us'
      ) {
        if (currentCookie === 'es-us') {
          unsetCookie(LOCALE_COOKIE_NAME_STRING);
        }
        setLanguage(DEFAULT_LOCALE);
      } else if (DEFAULT_LOCALE) {
        if (!currentCookie) {
          setUserLocale(
            DEFAULT_LOCALE,
            SUPPORTED_LOCALES,
            ipDetails?.data?.country_iso_code
          );
        }

        // We have to retrieve the cookie again because it might have been changed in the previous step.
        currentCookie = getCookie(LOCALE_COOKIE_NAME_STRING);
        if (
          !cookieLocaleRegex(window.location.pathname.split('/')[1]) &&
          currentCookie !== undefined &&
          currentCookie !== DEFAULT_LOCALE
        ) {
          navigate(changePathnameLocale(currentCookie, pageContext.slug));
        }
      }
    }

    let ignore = false;
    setLagnuage();
    return () => {
      ignore = true;
    };
  }, []);

  const setLanguage = (newLanguage: string) => {
    navigate(changePathnameLocale(newLanguage, pageContext.slug));
  };

  return (
    <LangSelector>
      <label htmlFor="language-selector">
        <span className="sr">
          {languageSelectorTranslation || 'Choose Your Language'}
        </span>
        <select
          className="language-dropdown"
          id="language-selector"
          onChange={(e) => setLanguage(e.target.value)}
        >
          {SUPPORTED_LOCALES?.map((item) => (
            <option
              key={item}
              value={item}
              selected={item === locale}
              aria-label={`${getLanguageFromLocale(item, SUPPORTED_LOCALES, DEFAULT_LOCALE)} - ${getCountryNameFromCountryCode(item, SUPPORTED_LOCALES, DEFAULT_LOCALE)}`}
            >
              {item}
            </option>
          ))}
        </select>
      </label>
    </LangSelector>
  );
};

export default LanguageSelectorComponent;
