import React from 'react';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Image as ImageProps } from './types/types';
import PreviewImage from './PreviewImage';

// once using local path:
// const localPath = '../../content/';
const Picture = styled.div<Partial<ImageProps>>`
  .gatsby-image-wrapper {
    max-width: ${(props: any) =>
      props.maxWidth ? `${props.maxWidth}px` : '100%'};
    height: auto;
    margin: auto;

    @media (max-width: 768px) {
      max-width: ${(props: any) =>
        props.maxWidthSmall ? `${props.maxWidthSmall}px` : '100%'};
      height: auto;

      div:first-child {
        padding-top: ${(props: any) =>
          props.mobileImage
            ? `${100 * props.mobileImage}% !important `
            : 'inherit'};
      }
    }

    img {
      max-width: 100%;
      object-fit: contain;
    }
  }

  img.img__padding {
    &--0 {
      padding: 0;
    }

    &--1x {
      padding: 1rem;
    }

    &--2x {
      padding: 2rem;
    }

    &--3x {
      padding: 3rem;
    }

    @media (max-width: 768px) {
      &--0-sm {
        padding: 0 !important;
      }

      &--1x-sm {
        padding: 1rem !important;
      }

      &--2x-sm {
        padding: 2rem !important;
        margin-bottom: 0 !important;
      }

      &--3x-sm {
        padding: 3rem !important;
        margin-bottom: 0 !important;
      }
    }
  }
`;

const Image = ({ fields }: Partial<ImageProps>) => {
  let image;
  let images;
  if (fields?.imageSm?.gatsbyImageData) {
    images = withArtDirection(getImage(fields.image.gatsbyImageData), [
      {
        media: '(max-width: 768px)',
        image: getImage(fields?.imageSm?.gatsbyImageData),
      },
    ]);
  } else if (fields?.image?.gatsbyImageData) {
    image = getImage(fields?.image?.gatsbyImageData);
  } else if (fields?.gatsbyImageData) {
    image = getImage(fields?.gatsbyImageData);
  }
  if (!image) {
    image = fields?.image?.gatsbyImageData || fields?.gatsbyImageData;
  }

  return images || image ? (
    <Picture
      maxWidth={fields.maxWidth}
      maxWidthSmall={fields.maxWidthSmall}
      mobileImage={fields?.imageSm?.gatsbyImageData?.height || null}
    >
      <GatsbyImage
        sizes="(max-width: 1440px) 100vw, 1440px"
        image={images || image}
        alt={fields?.alternativeText || ''}
        loading={fields?.loading === 'eager' ? 'eager' : 'lazy'}
        {...(fields.paddingSmall === null && fields.paddingLarge === null
          ? null
          : {
              className: `${
                fields.paddingSmall
                  ? // eslint-disable-next-line max-len
                    `img__padding--${fields?.paddingSmall}-sm`
                  : ''
              } ${fields.paddingLarge ? `img__padding--${fields?.paddingLarge}` : ''}`,
            })}
      />
    </Picture>
  ) : (
    <PreviewImage
      fields={fields}
      maxWidth={fields.maxWidth}
      maxWidthSmall={fields.maxWidthSmall}
    />
  );
};

export default Image;
