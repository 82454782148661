import React, { Suspense } from 'react';
import EmbeddedScript from '../../../../EmbeddedScript';
import renderImageComponent from '../../../../renderImageComponent';
import { HeroTemplate as HeroTemplateProps } from './types';
import { Grid } from '@sezzle/sezzle-mui';
import HeroText from '../../HeroText';
import { StyledHeroTemplateWrapper } from '../styles';
import ClientOnly from '../../../../ClientOnly';

const HeroTemplate1 = ({
  h1Header,
  h1HeaderFont,
  mobileCtas,
  buttonPlacementBelowSubcopy,
  heroImage,
  bodyCopy1,
  bodyCopy2,
  bodyCopy3,
  embeddedScript,
  bodyCopy1Font,
  bodyCopy2Font,
  bodyCopy3Font,
  extraSmallLeftSide,
  smallLeftSide,
  mediumLeftSide,
  largeLeftSide,
  phoneCaptureForm,
}: HeroTemplateProps) => {
  const leftXS = parseInt(extraSmallLeftSide) || 12;
  const leftS = parseInt(smallLeftSide) || 12;
  const leftM = parseInt(mediumLeftSide) || 6;
  const leftL = parseInt(largeLeftSide) || 4;

  const rightXS = 12;
  const rightS = 12;
  const rightM = 12 - leftM;
  const rightL = 12 - leftL;

  return (
    <StyledHeroTemplateWrapper
      container
      spacing={3}
      data-component-type="hero-template-1"
    >
      <Grid
        item
        xs={leftXS}
        sm={leftS}
        md={leftM}
        lg={leftL}
      >
        <HeroText
          h1Header={h1Header}
          h1HeaderFont={h1HeaderFont}
          mobileCtas={mobileCtas}
          buttonPlacementBelowSubcopy={buttonPlacementBelowSubcopy}
          bodyCopy1={bodyCopy1}
          bodyCopy1Font={bodyCopy1Font}
          bodyCopy2={bodyCopy2}
          bodyCopy2Font={bodyCopy2Font}
          bodyCopy3={bodyCopy3}
          bodyCopy3Font={bodyCopy3Font}
          phoneCaptureForm={phoneCaptureForm}
        />
        <ClientOnly>
          <Suspense fallback={<div />}>
            {embeddedScript &&
              embeddedScript.map((item, index) => (
                <EmbeddedScript
                  key={index}
                  scriptSource={item.scriptSource}
                  scriptInnerHtml={item.scriptInnerHtml}
                  scriptAppendToId={item.scriptAppendToId}
                  embeddedHtml={item.embeddedHtml}
                />
              ))}
          </Suspense>
        </ClientOnly>
      </Grid>
      <Grid
        item
        xs={rightXS}
        sm={rightS}
        md={rightM}
        lg={rightL}
      >
        {renderImageComponent(
          heroImage?.sys?.contentType?.sys?.id ||
            heroImage?.contentfulContentType,
          heroImage
        )}
      </Grid>
    </StyledHeroTemplateWrapper>
  );
};

export default HeroTemplate1;
