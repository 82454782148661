/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import Image from '../Image';
import { ImageWithTitleAndDescription as ImageWithTitleAndDescriptionProps } from './types/types';

const StyledImageWithTitleAndDescription = styled.div<
  Partial<ImageWithTitleAndDescriptionProps>
>`
  div.container--img {
    height: ${(props) =>
      props.imageMaxHeight ? `${props.imageMaxHeight}px` : 'auto'};
    text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};

    img {
      margin-bottom: 20px;
      height: ${(props) =>
        props.imageMaxHeight ? `${props.imageMaxHeight}px` : 'auto'};
      object-fit: contain !important;
    }
  }

  h2.headline--3 {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    line-height: 21.6px;
    letter-spacing: 0.05em;
  }

  div.copy {
    text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  }
`;

const ImageWithTitleAndDescription = ({
  textAlign,
  imageMaxHeight,
  title,
  nestedHeader,
  description,
  image,
}: ImageWithTitleAndDescriptionProps) => {
  return (
    <StyledImageWithTitleAndDescription
      textAlign={textAlign}
      imageMaxHeight={imageMaxHeight}
    >
      <div className="container--img">
        <Image fields={image} />
      </div>
      <div className="copy">
        {title ? (
          nestedHeader ? (
            <h3>{title}</h3>
          ) : (
            <h2 className="headline--3">{title}</h2>
          )
        ) : null}
        {description && (
          <p className="body__copy--small">{description.description}</p>
        )}
      </div>
    </StyledImageWithTitleAndDescription>
  );
};

export default ImageWithTitleAndDescription;
