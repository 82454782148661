import React from 'react';
import styled from 'styled-components';
import { LinkedContent as LinkedContentProps } from './types/types';

export const NavigationStyledLinks = styled.li`
  padding: 0;

  button.btn-link {
    font-family: Satoshi;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 36px;
    padding-bottom: 0;
    display: inline-block;
    color: #7a7287;
    letter-spacing: 0;
    background: transparent;
    border: none;

    @media (max-width: 576px) {
      font-size: 1rem;
    }

    &.active {
      border-bottom: 1px solid #382757;
      color: #382757;
    }

    &:hover,
    &:focus {
      border-bottom: ${(props: any) => `1px solid ${props.theme.secondary}`};
      color: ${(props: any) => `${props.theme.secondary}`};
      cursor: pointer;
    }
  }
`;

const LinkedNavigationStyleLinks = ({
  changedIdCallback,
  showId,
  index,
  displayText,
}: Partial<LinkedContentProps>) => {
  return (
    <NavigationStyledLinks>
      <button
        type="button"
        className={showId === index ? 'btn-link active' : 'btn-link trk-ga'}
        onClick={() => changedIdCallback(index)}
      >
        {displayText}
      </button>
    </NavigationStyledLinks>
  );
};

export default LinkedNavigationStyleLinks;
