import React from 'react';
import styled from 'styled-components';
import { renderCTAOptions } from '../renderCTAOptions';
import { HeaderAndDescription as HeaderAndDescriptionProps } from './types/types';

const StyledHeaderAndDescription = styled.div`
  margin: auto;
  text-align: left;
  padding: 4rem;

  @media (max-width: 992px) {
    padding: 2rem;
  }

  h2.heading-1 {
    font-size: 2rem;
    line-height: 36.8px;
    font-weight: normal;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }

  p {
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }

  .body__copy--display-sm {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .body__copy--display-lg {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }

  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const HeaderAndDescription = ({
  superHeading,
  description,
  descriptionSmall,
  button,
}: HeaderAndDescriptionProps) => {
  return (
    <StyledHeaderAndDescription>
      <h2 className="heading-1">{superHeading}</h2>
      <p
        className={`body__copy--small ${descriptionSmall?.descriptionSmall ? 'body__copy--display-lg' : ''}`}
      >
        {description?.description}
      </p>
      {descriptionSmall?.descriptionSmall && (
        <p className="body__copy--small body__copy--display-sm">
          {descriptionSmall?.descriptionSmall}
        </p>
      )}
      {button &&
        renderCTAOptions(
          button?.sys?.contentType?.sys?.id || button?.contentfulContentType,
          button,
          true
        )}
    </StyledHeaderAndDescription>
  );
};

export default HeaderAndDescription;
