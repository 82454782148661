import React from 'react';
import parse from 'html-react-parser';
import { Text as TextProps } from './types/types';
import TagWrapper from '../TagWrapper';

const Text = ({ tag, text, textFontSize, fontSize, textAlign }: TextProps) => {
  const t = React.useMemo(() => parse(text?.text || text), [text]);
  const pClassName =
    tag === 'p' ? `body__copy--${textFontSize || 'small'}` : undefined;

  return (
    <TagWrapper
      tag={tag}
      fontSize={fontSize}
      textAlign={textAlign}
      className={pClassName}
    >
      <>{t}</>
    </TagWrapper>
  );
};

export default Text;
